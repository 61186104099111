import { RangeOf } from '@platform-ui-kit/components-library/dist/types/types/numberRange'

export const mimeToExtensionMapping = {
  'audio/aiff': ['.aif'],
  'audio/x-cda': ['.cda'],
  'audio/midi': ['.mid'],
  'audio/mp3': ['.mp3'],
  'audio/mpeg': ['.mp3'],
  'audio/mpa': ['.mpa'],
  'audio/ogg': ['.ogg'],
  'audio/x-ms-wma': ['.wma'],
  'application/vnd.ms-wpl': ['.wpl'],
  'application/x-7z-compressed': ['.7z'],
  'application/x-arj': ['.arj'],
  'application/x-rar-compressed': ['.rar'],
  'application/x-rpm': ['.rpm'],
  'application/gzip': ['.tar.gz'],
  'application/x-compress': ['.z'],
  'application/zip': ['.zip'],
  'multipart/x-zip': ['.zip'],
  'application/x-zip-compressed': ['.zip'],
  'text/csv': ['.csv'],
  'application/octet-stream': ['.dat', '.db', '.dbf'],
  'text/plain': ['.log', '.txt'],
  'application/msaccess': ['.mdb'],
  'application/x-spss-sav': ['.sav'],
  'application/sql': ['.sql'],
  'application/x-tar': ['.tar'],
  'application/xml': ['.xml'],
  'application/postscript': ['.ai', '.ps'],
  'image/bmp': ['.bmp'],
  'image/gif': ['.gif'],
  'image/x-icon': ['.ico'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'application/vnd.adobe.photoshop': ['.psd'],
  'image/svg+xml': ['.svg'],
  'image/tiff': ['.tif', '.tiff'],
  'image/webp': ['.webp'],
  'application/vnd.apple.keynote': ['.key'],
  'application/pkcs8': ['.key'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.ms-powerpoint': ['.pps', '.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'video/3gpp2': ['.3g2'],
  'video/3gp': ['.3gp'],
  'video/x-msvideo': ['.avi'],
  'video/avi': ['.avi'],
  'video/x-flv': ['.flv'],
  'video/h264': ['.h264'],
  'video/x-m4v': ['.m4v'],
  'video/x-matroska': ['.mkv'],
  'video/quicktime': ['.mov'],
  'video/mp4': ['.mp4'],
  'video/mpeg': ['.mpg'],
  'application/vnd.rn-realmedia': ['.rm'],
  'application/x-shockwave-flash': ['.swf'],
  'video/dvd': ['.vob'],
  'video/webm': ['.webm'],
  'video/x-ms-wmv': ['.wmv'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/pdf': ['.pdf'],
  'application/rtf': ['.rtf'],
  'application/x-tex': ['.tex'],
  'application/wordperfect': ['.wpd'],
  'application/x-iwork-keynote-sffkey': ['.key'],
  'application/vnd.rar': ['.rar'],
  'audio/vnd.wave': ['.waw'],
  'audio/wav': ['.waw'],
  'audio/wave': ['.waw'],
  'audio/x-pn-wav': ['.waw'],
  'audio/x-wav': ['.waw'],
  'video/ogg': ['.ogg'],
}

export const FilesFormat = {
  music: ['.aif', '.cda', '.mid', '.mp3', '.mpa', '.ogg', '.wav', '.wma', '.wpl'],
  compressed: ['.7z', '.arj', '.rar', '.rpm', '.tar.gz', '.z', '.zip'],
  data: ['.csv', '.dat', '.db', '.dbf', '.log', '.mdb', '.sav', '.sql', '.tar', '.xml'],
  spreadsheet: ['.ods', '.xls', '.xlsm', '.xlsx'],
  images: ['.ai', '.bmp', '.gif', '.ico', '.jpeg', '.jpg', '.png', '.ps', '.psd', '.svg', '.tif', '.tiff', '.webp'],
  documents: ['.doc', '.docx', '.odt', '.pdf', '.rtf', '.tex', '.txt', '.wpd'],
  presentations: ['.key', '.odp', '.pps', '.ppt', '.pptx'],
  videos: [
    '.3g2',
    '.3gp',
    '.avi',
    '.flv',
    '.h264',
    '.m4v',
    '.mkv',
    '.mov',
    '.mp4',
    '.mpg',
    '.rm',
    '.swf',
    '.vob',
    '.webm',
    '.wmv',
  ],
}

export type FileTypes = keyof typeof FilesFormat

export const FilesColorFormat: Record<FileTypes, RangeOf<9>> = {
  music: 1,
  compressed: 2,
  data: 3,
  images: 4,
  presentations: 5,
  spreadsheet: 6,
  videos: 7,
  documents: 8,
}

export const findKeyByExtension = (extension: string): string[] => {
  return Object.entries(mimeToExtensionMapping)
    .filter(([, keys]) => keys.includes(extension))
    .map(([mime]) => mime)
}

export const getAllFileFormatKeys = () => Object.keys(FilesFormat) as FileTypes[]

export const FileFormatMapByCategory: Partial<Record<string, FileTypes>> = Object.keys(FilesFormat).reduce(
  (prev, curr) => {
    FilesFormat[curr as FileTypes].forEach(extension => (prev[extension] = curr as FileTypes))
    return prev
  },
  {} as Partial<Record<string, FileTypes>>,
)
