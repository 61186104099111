// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eLgaD{width:150px;height:150px}`, "",{"version":3,"sources":["webpack://./src/components/common/emptyState/EmptyState.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,YAAA","sourcesContent":[".image {\n  width: 150px;\n  height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `eLgaD`
};
export default ___CSS_LOADER_EXPORT___;
