import { useOs } from '@wpp-open/react'
import { useCallback, useContext } from 'react'

import { useCheckCaasRequirements } from 'hooks/application/useCheckCaasRequirements'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { ApplicationItem, CanvasApplicationType } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
}

export const useOpenApplication = ({ application }: Props) => {
  const {
    osApi: { navigation },
  } = useOs()

  const projectContext = useContext(ProjectContext)
  const project = projectContext?.project

  const { openProjectLeanApp } = navigation
  const { isCaasAppHavePermission, isCaasHasCurrentWorkspace } = useCheckCaasRequirements(application)

  const handleOpenApplication = useCallback(async () => {
    const { type, isOutdated, name } = application

    if (!project) {
      console.warn(`Can't run "${name}" application, project is absent.`)
      return
    }

    const canRunApp = isCaasAppHavePermission && isCaasHasCurrentWorkspace && !isOutdated
    if (!canRunApp) {
      console.warn(`Can't run "${name}" application, please check app configuration.`)
      return
    }

    /**
     Converts a Miro board to a Miro instance if the input type is "board".
     When creating an instance, there is a possibility of encountering a 401 error,
     indicating that the user does not have a non-expired token for Miro backend requests.
     In such cases, an authorization call should be made for the user.
     Upon successful authorization, the backend will store the token,
     allowing us to create the instance. After authorization, the user will be redirected
     to the origin page. To identify that the user attempted to open this app,
     the application ID is passed as a parameter via browser router history API.
     */

    const isMiro = type === CanvasApplicationType.MIRO_BOARD_INSTANCE
    const miroSettings = isMiro
      ? {
          leanAppId: application.externalAppOriginId!,
          instanceId: application.externalAppId,
        }
      : {}

    openProjectLeanApp({
      leanAppId: application.externalAppId,
      projectId: project?.id,
      itemId: application.id,
      ...miroSettings,
    })
  }, [application, isCaasAppHavePermission, isCaasHasCurrentWorkspace, openProjectLeanApp, project])

  return { handleOpenApplication }
}
