import { WppSkeleton, WppSpinner, WppTab, WppTabs } from '@platform-ui-kit/components-library-react'
import { matchPath, useLocation } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { BackToProjectsButton } from 'pages/components/backToProjectsButton/BackToProjectsButton'
import { WorkflowSkeleton } from 'pages/components/projectSkeleton/components/workflow/WorkflowSkeleton'
import styles from 'pages/project/Project.module.scss'
import { projectTabs } from 'utils/project'
import { routesManager } from 'utils/routesManager'

export const ProjectSkeleton = ({ isCanvasLoading }: { isCanvasLoading: boolean }) => {
  const location = useLocation()

  const currentTab =
    matchPath(`${routesManager.project.root.pattern}/:tab`, location.pathname)?.params.tab ||
    routesManager.project.workflow.shortPattern

  return (
    <Flex direction="column" className={styles.container}>
      <BackToProjectsButton />
      <Flex gap={24} direction="column">
        <Flex>
          <WppSkeleton variant="rectangle" height="40px" width="100%" />
        </Flex>

        <Flex gap={24}>
          <WppTabs value={currentTab} size="s" className={styles.nav}>
            {projectTabs.map(({ id, value }) => (
              <WppTab key={id} value={id}>
                {value}
              </WppTab>
            ))}
          </WppTabs>
        </Flex>

        {getSkeleton(currentTab, isCanvasLoading)}
      </Flex>
    </Flex>
  )
}

const getSkeleton = (tab: string, isCanvasLoading: boolean) => {
  switch (tab) {
    case routesManager.project.workflow.shortPattern:
      return <WorkflowSkeleton isCanvasLoading={isCanvasLoading} />
  }

  return (
    <Flex justify="center" align="center" className={styles.spinner}>
      <WppSpinner size="l" />
    </Flex>
  )
}
