import styles from 'components/svg/common.module.scss'

export const SvgEmptySearch = (props: JSX.IntrinsicElements['svg']) => (
  <svg {...props} width="188" height="150" viewBox="0 0 188 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
      className={styles.fillPrimary200}
    />
    <g filter="url(#filter0_d_1507_86035)">
      <path
        d="M41.5 34H176.5C177.826 34 179.098 34.5268 180.036 35.4645C180.973 36.4021 181.5 37.6739 181.5 39V64C181.5 65.3261 180.973 66.5979 180.036 67.5355C179.098 68.4732 177.826 69 176.5 69H41.5C40.1739 69 38.9021 68.4732 37.9645 67.5355C37.0268 66.5979 36.5 65.3261 36.5 64V39C36.5 37.6739 37.0268 36.4021 37.9645 35.4645C38.9021 34.5268 40.1739 34 41.5 34V34Z"
        className={styles.fillWhite}
      />
    </g>
    <path
      d="M105.5 42H79.5C77.8431 42 76.5 43.3431 76.5 45C76.5 46.6569 77.8431 48 79.5 48H105.5C107.157 48 108.5 46.6569 108.5 45C108.5 43.3431 107.157 42 105.5 42Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M123.5 55H79.5C77.8431 55 76.5 56.3431 76.5 58C76.5 59.6569 77.8431 61 79.5 61H123.5C125.157 61 126.5 59.6569 126.5 58C126.5 56.3431 125.157 55 123.5 55Z"
      className={styles.fillPrimary100}
    />
    <path
      d="M68.5 51.5C68.5 43.4919 62.0081 37 54 37C45.9919 37 39.5 43.4919 39.5 51.5C39.5 59.5081 45.9919 66 54 66C62.0081 66 68.5 59.5081 68.5 51.5Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M53.1002 56.194C54.3415 56.1938 55.5472 55.7785 56.5252 55.014L59.6002 58.089L60.5892 57.1L57.5132 54.024C58.286 53.0248 58.6977 51.7933 58.681 50.5303C58.6643 49.2672 58.2203 48.047 57.4214 47.0685C56.6225 46.0901 55.5157 45.4111 54.2815 45.1422C53.0473 44.8732 51.7583 45.0303 50.6248 45.5877C49.4912 46.1451 48.5799 47.07 48.0393 48.2116C47.4987 49.3533 47.3607 50.6444 47.6478 51.8745C47.9349 53.1046 48.6301 54.2013 49.6203 54.9857C50.6104 55.7701 51.837 56.196 53.1002 56.194ZM53.1002 46.4C53.9309 46.4 54.7429 46.6463 55.4336 47.1078C56.1243 47.5693 56.6626 48.2253 56.9805 48.9927C57.2984 49.7602 57.3816 50.6047 57.2195 51.4194C57.0574 52.2341 56.6574 52.9825 56.0701 53.5699C55.4827 54.1572 54.7343 54.5572 53.9196 54.7193C53.1049 54.8814 52.2604 54.7982 51.4929 54.4803C50.7255 54.1624 50.0695 53.6241 49.608 52.9334C49.1465 52.2427 48.9002 51.4307 48.9002 50.6C48.9002 49.4861 49.3427 48.4178 50.1304 47.6302C50.918 46.8425 51.9863 46.4 53.1002 46.4Z"
      className={styles.fillWhite}
    />
    <g filter="url(#filter1_d_1507_86035)">
      <path
        d="M161.5 79H26.5C23.7386 79 21.5 81.2386 21.5 84V109C21.5 111.761 23.7386 114 26.5 114H161.5C164.261 114 166.5 111.761 166.5 109V84C166.5 81.2386 164.261 79 161.5 79Z"
        className={styles.fillWhite}
      />
    </g>
    <path
      d="M90.5 87H64.5C62.8431 87 61.5 88.3431 61.5 90C61.5 91.6569 62.8431 93 64.5 93H90.5C92.1569 93 93.5 91.6569 93.5 90C93.5 88.3431 92.1569 87 90.5 87Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M108.5 100H64.5C62.8431 100 61.5 101.343 61.5 103C61.5 104.657 62.8431 106 64.5 106H108.5C110.157 106 111.5 104.657 111.5 103C111.5 101.343 110.157 100 108.5 100Z"
      className={styles.fillPrimary100}
    />
    <path
      d="M53.5 96.5C53.5 88.4919 47.0081 82 39 82C30.9919 82 24.5 88.4919 24.5 96.5C24.5 104.508 30.9919 111 39 111C47.0081 111 53.5 104.508 53.5 96.5Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M38.1002 101.194C39.3415 101.194 40.5472 100.778 41.5252 100.014L44.6002 103.089L45.5892 102.1L42.5132 99.024C43.286 98.0248 43.6977 96.7933 43.681 95.5303C43.6643 94.2672 43.2203 93.047 42.4214 92.0685C41.6225 91.0901 40.5157 90.4111 39.2815 90.1422C38.0473 89.8732 36.7583 90.0303 35.6248 90.5877C34.4912 91.1451 33.5799 92.07 33.0393 93.2116C32.4987 94.3533 32.3607 95.6444 32.6478 96.8745C32.9349 98.1046 33.6301 99.2013 34.6203 99.9857C35.6104 100.77 36.837 101.196 38.1002 101.194ZM38.1002 91.4C38.9309 91.4 39.7429 91.6463 40.4336 92.1078C41.1243 92.5693 41.6626 93.2253 41.9805 93.9927C42.2984 94.7602 42.3816 95.6047 42.2195 96.4194C42.0574 97.2341 41.6574 97.9825 41.0701 98.5699C40.4827 99.1572 39.7343 99.5572 38.9196 99.7193C38.1049 99.8814 37.2604 99.7982 36.4929 99.4803C35.7255 99.1624 35.0695 98.6241 34.608 97.9334C34.1465 97.2427 33.9002 96.4307 33.9002 95.6C33.9002 94.4861 34.3427 93.4178 35.1304 92.6302C35.918 91.8425 36.9863 91.4 38.1002 91.4Z"
      className={styles.fillWhite}
    />
    <defs>
      <filter
        id="filter0_d_1507_86035"
        x="30.5"
        y="31"
        width="157"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1507_86035" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1507_86035" result="shape" />
      </filter>
      <filter
        id="filter1_d_1507_86035"
        x="15.5"
        y="76"
        width="157"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1507_86035" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1507_86035" result="shape" />
      </filter>
    </defs>
  </svg>
)
