import { fetchLeanAppsApi } from 'api/applications/fetchers/fetchLeanAppsApi'
import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteLeanAppsApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.LEAN_APP_INFINITE,
  fetcher: fetchLeanAppsApi,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: lastPage => {
    return lastPage.data.paginator.totalPages === lastPage.data.paginator.page
      ? undefined
      : lastPage.data.paginator.page + 1
  },
})
