import { WppFilterButton, WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'

import { Flex } from 'components/common/flex/Flex'
import { SvgNoWorkflow } from 'components/svg/SvgNoWorkflow'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { WorkflowSkeleton } from 'pages/components/projectSkeleton/components/workflow/WorkflowSkeleton'
import FluidCanvas from 'pages/project/components/canvas/components/fluidCanvas/FluidCanvas'
import { Item } from 'pages/project/components/canvas/components/item/Item'
import { Phase } from 'pages/project/components/canvas/components/phase/Phase'
import { viewSettingsModal } from 'pages/project/components/canvas/components/viewSettingsModal/ViewSettingsModal'
import styles from 'pages/project/components/workflow/Workflow.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { PresetType } from 'types/projects/projectViewSettings'
import { PhaseItem } from 'types/projects/workflow'
import { routesManager } from 'utils/routesManager'

export const Workflow = () => {
  const { t } = useTranslation()
  const { project, isCanvasLoading, canvas, fluidData, isFluidLoading, viewSettings } = useContext(ProjectContext)

  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const { id } = project

  const counterNumber = useMemo(() => {
    return viewSettings?.preset !== PresetType.DEFAULT ? 1 : 0
  }, [viewSettings])

  return (
    <>
      {isCanvasLoading || isFluidLoading ? (
        <Flex direction="column" className={styles.container} gap={20}>
          <WorkflowSkeleton isCanvasLoading={isCanvasLoading} />
        </Flex>
      ) : (project.processType === ProcessType.LINEAR && canvas?.columnOrder?.length) ||
        (project.processType === ProcessType.FLUID && fluidData?.containers?.length) ? (
        <>
          {project.processType === ProcessType.LINEAR && (
            <>
              <Flex className={styles.filters} justify="end">
                <WppTooltip
                  text={t('project.canvas.preferences_button_tooltip')!}
                  config={{
                    trigger: counterNumber >= 1 ? 'mouseenter' : 'manual',
                    hideOnClick: false,
                    maxWidth: 400,
                  }}
                >
                  <WppFilterButton
                    counter={counterNumber}
                    data-testid="view-settings-button"
                    onClick={() => viewSettingsModal()}
                  >
                    {t('project.canvas.btn_preferences')}
                  </WppFilterButton>
                </WppTooltip>
              </Flex>

              <Flex direction="column" className={styles.container} gap={20}>
                <Flex>
                  <Flex className={styles.preview}>
                    {canvas.columnOrder.map((order, index) => {
                      const column = canvas.columns[order]
                      const tasks = canvas.columns[order].taskIds.map(id => canvas.tasks[id]) as PhaseItem[]

                      return (
                        <Phase key={column.id} column={column} index={index} variant="primary" preview>
                          <Flex direction="column" gap={8} className={styles.itemsWrapper}>
                            {tasks.map((t, i) => (
                              <Item variant="primary" key={t.id} phaseItem={t} index={i} preview />
                            ))}
                          </Flex>
                        </Phase>
                      )
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}

          {project.processType === ProcessType.FLUID && fluidData && (
            <Flex className={styles.bodyFluid} gap={16} direction="column">
              <Flex justify="end">
                <WppTooltip
                  text={t('project.canvas.preferences_button_tooltip')!}
                  config={{
                    trigger: counterNumber >= 1 ? 'mouseenter' : 'manual',
                    hideOnClick: false,
                    maxWidth: 400,
                  }}
                >
                  <WppFilterButton
                    counter={counterNumber}
                    data-testid="view-settings-button"
                    onClick={() => viewSettingsModal()}
                  >
                    {t('project.canvas.btn_preferences')}
                  </WppFilterButton>
                </WppTooltip>
              </Flex>
              <ReactFlowProvider>
                <FluidCanvas fluidData={fluidData} projectId={project.id} preview />
              </ReactFlowProvider>
            </Flex>
          )}
        </>
      ) : (
        <Flex direction="column" justify="center" align="center" gap={8} className={styles.canvas}>
          <SvgNoWorkflow />
          <WppTypography type="l-midi" data-testid="no-workflow-text">
            {t('overview.no_workflow')}
          </WppTypography>
          {!isOwnerOrGlobalManage ? (
            <WppTypography type="s-body" data-testid="no-workflow-description-member">
              {t('overview.no_workflow_owner')}
            </WppTypography>
          ) : (
            <Flex data-testid="no-workflow-description">
              <WppTypography type="s-body">
                {t('overview.go_to')}
                <Link className={styles.link} to={routesManager.project.canvas.getURL({ id })}>
                  {t('overview.canvas')}
                </Link>
                {t('overview.to_create')}
              </WppTypography>
            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}
