import styles from 'components/svg/common.module.scss'

export const SvgNoWorkflowDemo = (props: JSX.IntrinsicElements['svg']) => (
  <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_60780_4225)">
      <circle cx="60" cy="60" r="60" className={styles.fillPrimary100} />
      <g opacity="0.89" filter="url(#filter0_d_60780_4225)">
        <path
          d="M18.75 36C18.75 32.6863 21.4363 30 24.75 30H95.25C98.5637 30 101.25 32.6863 101.25 36V84C101.25 87.3137 98.5637 90 95.25 90H24.75C21.4363 90 18.75 87.3137 18.75 84V36Z"
          className={styles.fillWhite}
        />
      </g>
      <path
        d="M18.75 36C18.75 32.6863 21.4363 30 24.75 30H95.25C98.5637 30 101.25 32.6863 101.25 36H18.75Z"
        className={styles.fillPrimary300}
      />
      <rect x="24" y="40.5" width="21" height="21" rx="4" className={styles.fillPrimary500} />
      <path
        d="M32.9402 46.92C31.5484 46.92 30.4202 48.0482 30.4202 49.44C30.4202 50.7095 31.359 51.7597 32.5802 51.9344V52.6602C30.9602 52.4811 29.7002 51.1077 29.7002 49.44C29.7002 47.6505 31.1508 46.2 32.9402 46.2C34.6079 46.2 35.9813 47.46 36.1604 49.08H35.4347C35.26 47.8587 34.2097 46.92 32.9402 46.92ZM34.6202 49.56C33.7586 49.56 33.0602 50.2584 33.0602 51.12V54.24C33.0602 55.1015 33.7586 55.8 34.6202 55.8H37.7402C38.6018 55.8 39.3002 55.1015 39.3002 54.24V51.12C39.3002 50.2584 38.6018 49.56 37.7402 49.56H34.6202ZM33.7802 51.12C33.7802 50.656 34.1563 50.28 34.6202 50.28H37.7402C38.2041 50.28 38.5802 50.656 38.5802 51.12V54.24C38.5802 54.7039 38.2041 55.08 37.7402 55.08H34.6202C34.1563 55.08 33.7802 54.7039 33.7802 54.24V51.12Z"
        className={styles.fillWhite}
      />
      <rect x="24" y="64.5" width="21" height="4.5" rx="1" className={styles.fillPrimary200} />
      <rect x="49.5" y="64.5" width="21" height="4.5" rx="1" className={styles.fillPrimary200} />
      <rect x="75" y="64.5" width="21" height="4.5" rx="1" className={styles.fillPrimary200} />
      <rect x="24" y="72" width="21" height="12.75" rx="1" className={styles.fillPrimary300} />
      <rect x="49.5" y="72" width="21" height="12.75" rx="1" className={styles.fillPrimary300} />
      <rect x="75" y="72" width="21" height="12.75" rx="1" className={styles.fillPrimary300} />
      <rect x="49.5" y="40.5" width="21" height="21" rx="4" className={styles.fillPrimary500} />
      <rect x="75" y="40.5" width="21" height="21" rx="4" className={styles.fillPrimary500} />
      <path
        d="M58.4402 46.92C57.0484 46.92 55.9202 48.0482 55.9202 49.44C55.9202 50.7095 56.859 51.7597 58.0802 51.9344V52.6602C56.4602 52.4811 55.2002 51.1077 55.2002 49.44C55.2002 47.6505 56.6508 46.2 58.4402 46.2C60.1079 46.2 61.4813 47.46 61.6604 49.08H60.9347C60.76 47.8587 59.7097 46.92 58.4402 46.92ZM60.1202 49.56C59.2586 49.56 58.5602 50.2584 58.5602 51.12V54.24C58.5602 55.1015 59.2586 55.8 60.1202 55.8H63.2402C64.1018 55.8 64.8002 55.1015 64.8002 54.24V51.12C64.8002 50.2584 64.1018 49.56 63.2402 49.56H60.1202ZM59.2802 51.12C59.2802 50.656 59.6563 50.28 60.1202 50.28H63.2402C63.7041 50.28 64.0802 50.656 64.0802 51.12V54.24C64.0802 54.7039 63.7041 55.08 63.2402 55.08H60.1202C59.6563 55.08 59.2802 54.7039 59.2802 54.24V51.12Z"
        className={styles.fillWhite}
      />
      <path
        d="M83.9402 46.92C82.5484 46.92 81.4202 48.0482 81.4202 49.44C81.4202 50.7095 82.359 51.7597 83.5802 51.9344V52.6602C81.9602 52.4811 80.7002 51.1077 80.7002 49.44C80.7002 47.6505 82.1508 46.2 83.9402 46.2C85.6079 46.2 86.9813 47.46 87.1604 49.08H86.4347C86.26 47.8587 85.2097 46.92 83.9402 46.92ZM85.6202 49.56C84.7586 49.56 84.0602 50.2584 84.0602 51.12V54.24C84.0602 55.1015 84.7586 55.8 85.6202 55.8H88.7402C89.6018 55.8 90.3002 55.1015 90.3002 54.24V51.12C90.3002 50.2584 89.6018 49.56 88.7402 49.56H85.6202ZM84.7802 51.12C84.7802 50.656 85.1563 50.28 85.6202 50.28H88.7402C89.2041 50.28 89.5802 50.656 89.5802 51.12V54.24C89.5802 54.7039 89.2041 55.08 88.7402 55.08H85.6202C85.1563 55.08 84.7802 54.7039 84.7802 54.24V51.12Z"
        className={styles.fillWhite}
      />
      <ellipse cx="10.0956" cy="48.0001" rx="2.24989" ry="2.25" className={styles.fillPrimary300} />
      <path
        d="M63.2541 13.6176C63.5866 12.3836 65.4136 12.3837 65.7462 13.6176L65.9205 14.2643C66.0412 14.7122 66.4129 15.0578 66.8835 15.1595L67.2463 15.238C68.5849 15.5274 68.5849 17.3569 67.2463 17.6463L66.8835 17.7247C66.4129 17.8265 66.0412 18.1721 65.9205 18.62L65.7462 19.2667C65.4136 20.5006 63.5866 20.5006 63.2541 19.2667L63.0798 18.62C62.9591 18.1721 62.5873 17.8265 62.1167 17.7247L61.754 17.6463C60.4153 17.3569 60.4153 15.5274 61.754 15.238L62.1167 15.1595C62.5873 15.0578 62.9591 14.7122 63.0798 14.2643L63.2541 13.6176Z"
        className={styles.fillPrimary300}
      />
      <circle cx="72.75" cy="21.9807" r="1.5" className={styles.fillPrimary400} />
      <path
        d="M52.9844 103.272C53.317 102.038 55.1439 102.038 55.4765 103.272L55.6508 103.918C55.7715 104.366 56.1432 104.712 56.6138 104.813L56.9765 104.892C58.3151 105.181 58.3151 107.011 56.9765 107.3L56.6138 107.379C56.1432 107.48 55.7715 107.826 55.6508 108.274L55.4765 108.921C55.1439 110.155 53.317 110.155 52.9844 108.921L52.8102 108.274C52.6894 107.826 52.3177 107.48 51.8472 107.379L51.4844 107.3C50.1458 107.011 50.1458 105.181 51.4844 104.892L51.8472 104.813C52.3177 104.712 52.6894 104.366 52.8102 103.918L52.9844 103.272Z"
        className={styles.fillPrimary400}
      />
      <ellipse cx="73.8456" cy="102" rx="2.24989" ry="2.25" className={styles.fillPrimary200} />
    </g>
    <defs>
      <filter
        id="filter0_d_60780_4225"
        x="6.75"
        y="22"
        width="106.5"
        height="84"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.709804 0 0 0 0.302 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_60780_4225" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_60780_4225" result="shape" />
      </filter>
      <clipPath id="clip0_60780_4225">
        <rect width="120" height="120" rx="60" className={styles.fillWhite} />
      </clipPath>
    </defs>
  </svg>
)
