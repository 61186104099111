import { WppButton, WppTooltip, WppActionButton, WppIconPitch } from '@platform-ui-kit/components-library-react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { useCheckCaasRequirements } from 'hooks/application/useCheckCaasRequirements'
import { useOpenApplication } from 'hooks/application/useOpenApplication'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { t } from 'i18next'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { ApplicationItem } from 'types/projects/workflow'
import { routesManager } from 'utils/routesManager'

interface Props {
  application: ApplicationItem
  onClose: () => void
  phaseId: string
}

export const AppDetailsLaunchApp = ({ application, onClose, phaseId }: Props) => {
  const projectContext = useContext(ProjectContext)
  const { handleOpenApplication } = useOpenApplication({ application })
  const navigate = useNavigate()

  const { isPermitted } = useIsPermitted()
  const { hasRole } = useHasProjectRole()

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const isOwner = hasRole([ProjectRole.OWNER]) || isGlobalManage

  const { isCaasAppHavePermission, isCaasHasCurrentWorkspace } = useCheckCaasRequirements(application)

  const showWarning =
    projectContext && (!isCaasAppHavePermission || !isCaasHasCurrentWorkspace || application?.isOutdated)

  return (
    <Flex justify="between" className={styles.actionsWrapper}>
      {projectContext.project.processType === ProcessType.LINEAR && isOwner ? (
        <WppActionButton
          onClick={() => {
            onClose()
            navigate(
              routesManager.project.demo.getURL({
                id: projectContext.project.id,
                selectedAppId: application.id,
                selectedPhaseId: phaseId,
              }),
            )
          }}
          data-testid="project-demo"
          variant="primary"
        >
          <WppIconPitch slot="icon-start" />

          {t('project.demo.title')}
        </WppActionButton>
      ) : (
        <div />
      )}

      <WppTooltip
        config={{ trigger: showWarning ? 'mouseenter' : 'manual', hideOnClick: false }}
        text={t('modals.app_details_modal.launch_button_error')!}
        className={styles.tooltip}
      >
        <WppButton
          variant="primary"
          size="m"
          onClick={handleOpenApplication}
          disabled={showWarning}
          data-testid="app-launch"
        >
          {t('common.btn_launch')}
        </WppButton>
      </WppTooltip>
    </Flex>
  )
}
