import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppFilterButton,
  WppInput,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSkeleton,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useContext, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'

import { TasksParams } from 'api/projects/fetchers/fetchProjectTasksApi'
import { useProjectTasksApi } from 'api/projects/queries/useProjectTasksApi'
import { Flex } from 'components/common/flex/Flex'
import { SvgNoWorkflow } from 'components/svg/SvgNoWorkflow'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import { showTasksFiltersModal } from 'pages/project/components/tasks/components/tasksFilters/TasksFiltersModal'
import { useUpdateTaskColumn } from 'pages/project/components/tasks/hooks/useUpdateTaskColumn'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { TasksStatusColumn } from 'pages/project/components/tasks/TasksStatusColumn'
import { allStatuses, alwaysShowStatuses, groupTasks } from 'pages/project/components/tasks/utils'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { DashboardOwnership } from 'types/projects/DashboardData'
import { TasksFilter, TaskStatus } from 'types/projects/tasks'

export const TasksSkeleton = () => (
  <Flex direction="row" gap={28} className={styles.tasksContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
  </Flex>
)

export const initialTasksFilters: TasksFilter = {
  search: '',
  archived: false,
  dueDateRanges: [],
}

export const Tasks = () => {
  const { t } = useTranslation()

  const { project } = useContext(ProjectContext)

  const [filters, setFilters] = useState<TasksFilter>(initialTasksFilters)
  const [subset, setSubset] = useState<DashboardOwnership>(DashboardOwnership.ALL)

  const mappedFilters: TasksParams = useMemo(
    () => ({
      projectId: project.id,
      text: filters.search,
      subset,
      statuses: filters.archived
        ? [TaskStatus.IN_PROGRESS, TaskStatus.TO_DO, TaskStatus.COMPLETED, TaskStatus.ARCHIVED]
        : [],
      dueDateRanges: filters.dueDateRanges,
    }),
    [filters.archived, filters.dueDateRanges, filters.search, project.id, subset],
  )
  const isDefaultStatusFilter = !mappedFilters.statuses?.length

  const statusColumns = useMemo(
    () =>
      isDefaultStatusFilter
        ? alwaysShowStatuses
        : allStatuses.filter(status => mappedFilters.statuses!.includes(status)),
    [isDefaultStatusFilter, mappedFilters.statuses],
  )

  const { updateItem, isLoading } = useUpdateTaskColumn({ filters: mappedFilters })

  const { data: tasks, isLoading: isTasksLoading } = useProjectTasksApi({
    params: {
      ...mappedFilters,
    },
  })

  const mappedTasks = useMemo(() => groupTasks({ tasks }), [tasks])
  const mappedGroupedTasks = useMemo(() => Object.entries(mappedTasks), [mappedTasks])

  const setSearchDebounced = useDebounceFn((e: WppInputCustomEvent<InputChangeEventDetail>) => {
    const search = e.detail.value || ''
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const handleCloseFilterModal = (newFilter: TasksFilter) => {
    setFilters(filters => ({ ...filters, ...newFilter }))
  }

  const filtersCounts = useMemo(() => getAppliedFilters(filters), [filters])
  const isFiltersApplied = !!filtersCounts || !!filters.search?.length

  return (
    <Flex direction="column" gap={24} className={styles.tasksContainer}>
      <Flex justify="between">
        <Flex gap={12}>
          <WppInput
            size="s"
            name="search"
            placeholder={t('project.tasks.field_search_placeholder')!}
            onWppChange={setSearchDebounced}
            type="search"
            data-testid="project-tasks-search"
            className={styles.searchInput}
          />
          <WppSegmentedControl
            slot="actions"
            value={subset}
            size="s"
            onWppChange={e => {
              setSubset(e.target.value as DashboardOwnership)
            }}
            data-testid="project-tasks-switch"
          >
            <WppSegmentedControlItem value={DashboardOwnership.ALL}>
              {t('project.tasks.filters.switch_all_tasks')}
            </WppSegmentedControlItem>
            <WppSegmentedControlItem value={DashboardOwnership.MY}>
              {t('project.tasks.filters.switch_my_tasks')}
            </WppSegmentedControlItem>
          </WppSegmentedControl>

          <WppFilterButton
            onClick={() => showTasksFiltersModal({ filters, onFiltersSave: handleCloseFilterModal })}
            data-testid="tasks-filter-button"
            counter={filtersCounts}
          >
            {t('project.files.btn_filter')!}
          </WppFilterButton>
        </Flex>
      </Flex>
      <>
        {isTasksLoading ? (
          <TasksSkeleton />
        ) : (
          <>
            {!tasks.length ? (
              <Flex className={styles.noData} align="center" justify="center" direction="column" gap={16}>
                <SvgNoWorkflow />
                <Flex justify="center" align="center" direction="column" gap={8}>
                  <WppTypography type="l-midi" data-testid="no-templates-text">
                    {t('project.tasks.no_tasks')}
                  </WppTypography>
                  <WppTypography type="s-body" data-testid="no-templates-text">
                    {isFiltersApplied
                      ? t('project.tasks.no_tasks_search_description')
                      : t('project.tasks.no_tasks_description')}
                  </WppTypography>
                </Flex>
              </Flex>
            ) : (
              <DndProvider backend={HTML5Backend}>
                <Flex direction="column">
                  {mappedGroupedTasks.map(([key, statuses]) => (
                    <Flex key={key} gap={28} className={styles.flexGrow}>
                      {statusColumns.map(statusKey => {
                        const tasks = statuses[statusKey]
                        if (!tasks) return null

                        return (
                          <TasksStatusColumn
                            statusKey={statusKey}
                            tasks={tasks}
                            key={statusKey}
                            projectId={project.id}
                            updateItem={updateItem}
                            filters={mappedFilters}
                          />
                        )
                      })}
                    </Flex>
                  ))}
                  {isLoading && <WaitOverlay />}
                </Flex>
              </DndProvider>
            )}
          </>
        )}
      </>
    </Flex>
  )
}

const WaitOverlay = () => {
  return (
    <Flex className={styles.waitOverlay} align="center" justify="center">
      <WppSpinner size="m" />
    </Flex>
  )
}
