import styles from 'components/svg/common.module.scss'

export const SvgNoWorkflow = (props: JSX.IntrinsicElements['svg']) => (
  <svg {...props} width="185" height="147" viewBox="0 0 185 147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1552_66179)">
      <g filter="url(#filter0_f_1552_66179)">
        <ellipse cx="94.2242" cy="121.221" rx="66.0865" ry="11.8899" className={styles.fillPrimary300} />
      </g>
      <path
        d="M97.0996 147C135.207 147 166.1 116.15 166.1 78.0938C166.1 40.0379 135.207 9.1875 97.0996 9.1875C58.992 9.1875 28.0996 40.0379 28.0996 78.0938C28.0996 116.15 58.992 147 97.0996 147Z"
        className={styles.fillPrimary200}
      />
      <g filter="url(#filter1_d_1552_66179)">
        <rect x="41.9004" y="49" width="52.1333" height="105.656" rx="4" className={styles.fillWhite} />
      </g>
      <rect x="46.0322" y="55.1875" width="21.6857" height="4.125" rx="2.0625" className={styles.fillPrimary100} />
      <rect x="46.0322" y="63.4374" width="43.3714" height="15.4688" rx="4" className={styles.fillPrimary200} />
      <rect x="46.0322" y="83.0312" width="43.3714" height="15.4688" rx="4" className={styles.fillPrimary300} />
      <g filter="url(#filter2_d_1552_66179)">
        <rect x="101.7" y="49" width="52.1333" height="105.656" rx="4" className={styles.fillWhite} />
      </g>
      <rect x="105.926" y="55.1875" width="21.6857" height="4.125" rx="2.0625" className={styles.fillPrimary100} />
      <rect x="105.926" y="63.4374" width="43.3714" height="15.4688" rx="4" className={styles.fillPrimary200} />
      <rect x="105.926" y="83.0312" width="43.3714" height="15.4688" rx="4" className={styles.fillPrimary300} />
      <rect x="105.926" y="102.625" width="43.3714" height="15.4688" rx="4" className={styles.fillPrimary200} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.18 34.2562C143.341 34.2562 144.283 33.316 144.283 32.1562C144.283 30.9964 143.341 30.0562 142.18 30.0562C141.019 30.0562 140.077 30.9964 140.077 32.1562C140.077 33.316 141.019 34.2562 142.18 34.2562ZM142.18 35.8312C144.212 35.8312 145.86 34.1858 145.86 32.1562C145.86 30.1266 144.212 28.4812 142.18 28.4812C140.148 28.4812 138.5 30.1266 138.5 32.1562C138.5 34.1858 140.148 35.8312 142.18 35.8312Z"
        className={styles.fillPrimary300}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.78 142.669C124.941 142.669 125.882 141.729 125.882 140.569C125.882 139.409 124.941 138.469 123.78 138.469C122.618 138.469 121.677 139.409 121.677 140.569C121.677 141.729 122.618 142.669 123.78 142.669ZM123.78 144.244C125.812 144.244 127.46 142.598 127.46 140.569C127.46 138.539 125.812 136.894 123.78 136.894C121.747 136.894 120.1 138.539 120.1 140.569C120.1 142.598 121.747 144.244 123.78 144.244Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M22.855 119.466C24.4743 119.202 25.7834 120.839 25.1984 122.395L24.4054 124.505C23.8264 126.045 21.8224 126.352 20.7881 125.059L19.3864 123.307C18.3521 122.014 19.0579 120.083 20.6604 119.823L22.855 119.466Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M158.801 88.9653C158.424 87.411 159.852 86.023 161.403 86.4364L164.526 87.2689C166.113 87.6919 166.636 89.6743 165.462 90.8156L163.099 93.1127C161.924 94.254 159.947 93.6851 159.561 92.0949L158.801 88.9653Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M92.5062 5.95418C92.9957 4.14027 95.6849 4.14027 96.1745 5.95418L96.431 6.90483C96.6087 7.56328 97.1559 8.07127 97.8485 8.22084L98.3825 8.33614C100.353 8.76161 100.353 11.4509 98.3825 11.8764L97.8485 11.9917C97.1559 12.1412 96.6087 12.6492 96.431 13.3077L96.1745 14.2583C95.6849 16.0722 92.9957 16.0722 92.5062 14.2583L92.2496 13.3077C92.0719 12.6492 91.5247 12.1412 90.8321 11.9917L90.2981 11.8764C88.3277 11.4509 88.3277 8.76161 90.2981 8.33614L90.8321 8.22084C91.5247 8.07127 92.0719 7.56328 92.2496 6.90483L92.5062 5.95418Z"
        className={styles.fillPrimary400}
      />
      <path
        d="M28.1058 87.723C28.5953 85.9091 31.2846 85.9091 31.7741 87.723L32.0306 88.6736C32.2083 89.3321 32.7555 89.8401 33.4481 89.9896L33.9821 90.1049C35.9525 90.5304 35.9525 93.2197 33.9821 93.6452L33.4481 93.7605C32.7555 93.91 32.2083 94.418 32.0306 95.0765L31.7741 96.0271C31.2846 97.841 28.5953 97.841 28.1058 96.0271L27.8492 95.0765C27.6715 94.418 27.1244 93.91 26.4317 93.7605L25.8977 93.6452C23.9273 93.2197 23.9273 90.5304 25.8977 90.1049L26.4317 89.9896C27.1244 89.8401 27.6715 89.3321 27.8492 88.6736L28.1058 87.723Z"
        className={styles.fillPrimary400}
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1552_66179"
        x="6.3647"
        y="87.5583"
        width="175.719"
        height="67.3259"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_1552_66179" />
      </filter>
      <filter
        id="filter1_d_1552_66179"
        x="35.9004"
        y="40"
        width="64.1338"
        height="117.656"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1552_66179" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1552_66179" result="shape" />
      </filter>
      <filter
        id="filter2_d_1552_66179"
        x="95.7002"
        y="40"
        width="64.1338"
        height="117.656"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1552_66179" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1552_66179" result="shape" />
      </filter>
      <clipPath id="clip0_1552_66179">
        <rect width="184" height="147" className={styles.fillWhite} transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
