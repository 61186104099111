// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kuqYW{width:150px;--input-select-min-width: 150px}.XeHwO{width:3px;height:32px;background:var(--wpp-grey-color-300);border-radius:6px}.Ue9A5{height:100%}.onHxO{width:200px}.onHxO::part(button){width:100%}.tNjOI{--mc-list-max-height: 205px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/appPikerModal/AppPickerModal.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,+BAAA,CAGF,OACE,SAAA,CACA,WAAA,CACA,oCAAA,CACA,iBAAA,CAGF,OACE,WAAA,CAGF,OACE,WAAA,CAEA,qBACE,UAAA,CAIJ,OACE,2BAAA","sourcesContent":[".select {\n  width: 150px;\n  --input-select-min-width: 150px;\n}\n\n.divider {\n  width: 3px;\n  height: 32px;\n  background: var(--wpp-grey-color-300);\n  border-radius: 6px;\n}\n\n.container {\n  height: 100%;\n}\n\n.showSelectedApps {\n  width: 200px;\n\n  &::part(button) {\n    width: 100%;\n  }\n}\n\n.selectVersionMenu {\n  --mc-list-max-height: 205px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `kuqYW`,
	"divider": `XeHwO`,
	"container": `Ue9A5`,
	"showSelectedApps": `onHxO`,
	"selectVersionMenu": `tNjOI`
};
export default ___CSS_LOADER_EXPORT___;
