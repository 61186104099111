import { ConnectionType } from 'pages/project/components/canvas/components/fluidCanvas/utils'
import { NativeAppVersionType } from 'types/products/nativeApp'
import { TaskStatus } from 'types/projects/tasks'

export enum CanvasApplicationType {
  LINK_DETACHED = 'link_detached',
  LINK_EMBEDDED = 'link_embedded',
  CUSTOM_PAGE = 'custom_page',
  CAAS = 'caas',
  MIRO_BOARD_INSTANCE = 'miro_board_instance',
  LEAN_APPS_NATIVE = 'lean_apps_native',
  NATIVE = 'native',
}

export interface Workflow {
  phases: Phase[]
}

export interface Phase {
  id: string
  name: string
  orderNumber: number
  description?: string
  startDate?: string
  endDate?: string
  assignUser?: string
  phaseItems: PhaseItem[]
}

export enum PhaseItemType {
  Application = 'application',
  Activity = 'activity',
  Task = 'task',
}

export interface ExternalLink {
  url: string
  alias: string
  id?: string
  createdAt: string
}

export interface ApplicationItem {
  id: string
  type: CanvasApplicationType
  externalAppId: string
  externalAppName: string
  externalAppOriginId?: string
  logoUrl: string
  agencyExternalId: string
  name?: string
  startDate?: string
  endDate?: string
  assignUser?: string
  isOnboarded?: boolean
  isOutdated?: boolean
  config?: ItemAppConfig
  orderNumber?: number
  presentationSlideId?: string
  task: TaskItem
  externalAppVersions: ExternalAppVersion[]
  externalAppVersionId?: string
  description?: string
}

interface ExternalAppVersion {
  id: string
  name: string
  versionType: NativeAppVersionType
}

export interface ActivityItem {
  id: string
  items: ActivityApplicationItem[]
  name?: string
  description?: string
  startDate?: string
  endDate?: string
  assignUser?: string
  externalLinks?: ExternalLink[]
  task: TaskItem
  files: ActivityFiles[]
}

export interface ActivityFiles {
  id: string
  key: string
  name: string
  size: string
  fileType: string
  scope: FileScope
  createdByEmail: string
}

export enum FileScope {
  PROJECT = 'PROJECT',
  ITEM = 'ITEM',
}

export interface ActivityApplicationItem {
  id: string
  orderNumber: number
  application: ApplicationItem
  activityItemId: string
}

interface TaskItem {
  orderNumber: number
  id: string
  status: TaskStatus
}

export interface PhaseItem<T = ApplicationItem | ActivityItem> {
  id: string
  phaseId: string
  itemType: PhaseItemType
  orderNumber: number
  item: T
}

export interface ItemAppConfig {
  bundle_url: string
  container_id: string
  os_route: string
  required_hierarchy: string[]
  view_permission_name: string
  window_library_name: string
}

enum AnchorSide {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface FluidWorkflow {
  containers: Container[]
  connections: IConnection[]
  items: (ApplicationItem | ActivityItem)[]
}

export enum ContainerType {
  ROOT = 'ROOT',
  NESTED = 'NESTED',
}

export interface Container {
  id: string
  itemId: string
  itemType: PhaseItemType
  coordinateX: number
  coordinateY: number
  containerType: ContainerType

  rootContainerId?: string
  orderNumberInRoot?: number
}

export interface IConnection {
  id: string
  type: ConnectionType
  sourceId: string
  sourceAnchorSide: AnchorSide
  targetId: string
  targetAnchorSide: AnchorSide
}
