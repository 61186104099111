import {
  WppActionButton,
  WppButton,
  WppIconReset,
  WppTypography,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, HierarchyNode, NavigationTreeNode } from '@wpp-open/core'
import { HierarchyContainerNodeId } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormSelect, FormSelectOption } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import { initialProjectFilters } from 'pages/dashboard/Dashboard'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { ProjectFilter, ProjectType, ProjectStatus, ProjectOwnership } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  filter: ProjectFilter
  handleCloseModal(filter?: ProjectFilter): void
}

const projectTypes = [
  { value: ProjectType.BLANK, label: 'Blank' },
  { value: ProjectType.PITCH, label: 'Pitch' },
  { value: ProjectType.WORKSHOP, label: 'Workshop' },
  { value: ProjectType.CAMPAIGN, label: 'Campaign' },
]

const mapToOptions = (nodes: NavigationTreeNode[] = []): FormSelectOption[] => {
  const mapValues = nodes.reduce((previousValue: Record<string, string[]>, currentValue: NavigationTreeNode) => {
    const { name } = currentValue

    if (!name) return previousValue
    return { ...previousValue, [name]: [...(previousValue[name] || []), currentValue.azId] as string[] }
  }, {})

  return Object.keys(mapValues)
    .map(key => ({ value: mapValues[key].join(','), label: key }))
    .sort((a, b) => a.label?.localeCompare(b.label))
}

const ProjectFilterModal: FC<Props> = ({ isOpen, onClose, onCloseComplete, handleCloseModal, filter }) => {
  const { t } = useTranslation()
  const { osContext } = useOs()

  const { mapping } = osContext.navigationTree
  const { hierarchyLevels } = osContext.tenant

  const form = useForm({ defaultValues: filter })

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    getValues,
  } = form

  const { CLIENT, MARKET, BRAND } = useMemo(
    () =>
      Object.keys(mapping).reduce((previousValue, currentValue) => {
        const element = mapping[currentValue]
        // @ts-ignore
        return { ...previousValue, [element.type]: [...(previousValue[element.type] || []), element] }
      }, {} as Record<HierarchyLevelType, NavigationTreeNode[]>),
    [mapping],
  )

  const { clientOptions, marketOptions, brandOptions } = useMemo(
    () => ({
      clientOptions: mapToOptions(CLIENT),
      marketOptions: mapToOptions(MARKET),
      brandOptions: mapToOptions(BRAND),
    }),
    [BRAND, CLIENT, MARKET],
  )

  const statusOptions = [
    { value: ProjectStatus.ACTIVE, label: t('project.status.active') },
    { value: ProjectStatus.ARCHIVED, label: t('project.status.archived') },
    { value: ProjectStatus.COMPLETED, label: t('project.status.completed') },
  ]

  const allowNavigationTypes = useMemo(() => osContext.tenant.hierarchyLevels.map(lvl => lvl.type), [osContext])

  const onSubmit = handleSubmit(value => {
    const { client, market, brand } = value
    const { children } = mapping[HierarchyContainerNodeId]

    const isWorkspaceEmpty = !!client.length || !!market.length || !!brand.length

    if (!isWorkspaceEmpty) {
      handleCloseModal({ ...value, workspace: undefined })
      onClose()
      return
    }

    const getAllChildren = (nodes: string[], isParentSelected = false) => {
      return nodes.reduce((acc: string[], curNodeId: string): string[] => {
        const currentNode = mapping[curNodeId] as HierarchyNode
        const currentNodeChildren = currentNode?.children || []

        // We need only HierarchyLevelType projects
        if (!allowNavigationTypes.includes(currentNode.type as HierarchyLevelType)) return acc

        // Get selected nodes from form value
        const selectedNodes = ((value[currentNode.type.toLowerCase() as keyof ProjectFilter] as string[]) || [])
          .map(el => el.split(','))
          .flat(Infinity) as string[]

        const checkIfNodeHasSelectedChildren = (currentHierarchyIndex: number) => {
          const selectedOnNextLvl = []
          for (let index = currentHierarchyIndex + 1; index < hierarchyLevels.length; index++) {
            const lvl = hierarchyLevels[index].type
            const nextLvlValue = value[lvl.toLowerCase() as keyof ProjectFilter]

            selectedOnNextLvl.push(!!nextLvlValue?.length)
          }

          return selectedOnNextLvl.some(Boolean)
        }

        const currentHierarchyIndex = hierarchyLevels.findIndex(h => h.type === currentNode.type)
        const nodeHasSelectedChildren = checkIfNodeHasSelectedChildren(currentHierarchyIndex)

        if (!!selectedNodes.length) {
          const isThisNodeSelected = selectedNodes.includes(currentNode.azId)

          if (isThisNodeSelected) {
            if (!!currentNodeChildren.length) {
              return acc.concat(
                nodeHasSelectedChildren ? [] : currentNode.azId,
                getAllChildren(currentNodeChildren, true),
              )
            } else {
              if (currentHierarchyIndex === hierarchyLevels.length - 1) return acc.concat(currentNode.azId)
              return nodeHasSelectedChildren ? acc : acc.concat(currentNode.azId)
            }
          } else return acc
        } else {
          if (!!currentNodeChildren.length) {
            return isParentSelected
              ? acc.concat(
                  nodeHasSelectedChildren ? [] : currentNode.azId,
                  getAllChildren(currentNodeChildren, isParentSelected),
                )
              : acc.concat(getAllChildren(currentNodeChildren, isParentSelected))
          } else {
            if (currentHierarchyIndex >= hierarchyLevels.length - 1)
              return isParentSelected ? acc.concat(currentNode.azId) : acc

            return nodeHasSelectedChildren ? acc : acc.concat(currentNode.azId)
          }
        }
      }, [])
    }

    handleCloseModal({ ...value, workspace: getAllChildren(children) })
    onClose()
  })

  const [status, ...watchFields] = watch(['status', 'brand', 'client', 'market', 'type', 'ownership'])
  const isStatusDirty =
    !status?.length || status?.some((value: keyof typeof ProjectStatus) => value !== ProjectStatus.ACTIVE)
  const isOwnershipDirty = getValues('ownership') !== ProjectOwnership.ALL

  const onReset = () => {
    reset(initialProjectFilters)
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          formConfig={{ onSubmit }}
          onWppSideModalClose={() => {
            handleCloseModal()
            onClose()
          }}
          onWppSideModalCloseComplete={onCloseComplete}
          size="s"
          data-testid="create-project-modal"
        >
          <WppTypography slot="header" type="2xl-heading" data-testid="filters-header">
            {t('modals.dashboard_filters.title')!}
          </WppTypography>
          <Flex slot="body" direction="column" gap={24}>
            <WppSegmentedControl
              size="s"
              onWppChange={event => setValue('ownership', event.detail.value as ProjectOwnership)}
              value={getValues('ownership') ?? ProjectOwnership.ALL}
              data-testid="dashboard-project-ownership-filter"
            >
              <WppSegmentedControlItem value={ProjectOwnership.ALL}>
                {t('dashboard.field_my_project_label_all')!}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={ProjectOwnership.MY}>
                {t('dashboard.field_my_project_label_my')!}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={ProjectOwnership.SHARED_WITH_ME}>
                {t('dashboard.field_my_project_label_shared')!}
              </WppSegmentedControlItem>
            </WppSegmentedControl>
            <FormSelect
              name="status"
              type="multiple"
              data-testid="status-select"
              options={statusOptions}
              labelConfig={{ text: t('modals.dashboard_filters.field_status_label') }}
              placeholder={t('modals.dashboard_filters.field_status_placeholder')!}
              withFolder
              required
            />
            <FormSelect
              name="type"
              type="multiple"
              data-testid="type-select"
              options={projectTypes}
              labelConfig={{ text: t('modals.dashboard_filters.field_type_label') }}
              placeholder={t('modals.dashboard_filters.field_type_placeholder')!}
              withFolder
              required
            />
            <FormSelect
              name="client"
              withSearch
              className={
                // @TODO: CL css var '--input-select-dropdown-max-height' doesn’t  work, remove when they will fix it
                clsx({ [styles.selectMaxHeight]: mapping[HierarchyContainerNodeId]?.children.length > 5 })
              }
              type="multiple"
              data-testid="client-select"
              options={clientOptions}
              labelConfig={{ text: t('modals.dashboard_filters.field_client_label') }}
              placeholder={t('modals.create_project.field_client_placeholder')!}
              withFolder
              required
            />
            <FormSelect
              name="market"
              withSearch
              className={
                // @TODO: CL css var '--input-select-dropdown-max-height' doesn’t  work, remove when they will fix it
                clsx({ [styles.selectMaxHeight]: mapping[HierarchyContainerNodeId]?.children.length > 5 })
              }
              type="multiple"
              data-testid="market-select"
              options={marketOptions}
              labelConfig={{ text: t('modals.dashboard_filters.field_market_label') }}
              placeholder={t('modals.create_project.field_market_placeholder')!}
              withFolder
              required
            />
            <FormSelect
              name="brand"
              withSearch
              className={
                // @TODO: CL css var '--input-select-dropdown-max-height' doesn’t  work, remove when they will fix it
                clsx({ [styles.selectMaxHeight]: mapping[HierarchyContainerNodeId]?.children.length > 5 })
              }
              type="multiple"
              data-testid="brand-select"
              options={brandOptions}
              labelConfig={{ text: t('modals.dashboard_filters.field_brand_label') }}
              placeholder={t('modals.create_project.field_brand_placeholder')!}
              withFolder
              required
            />
          </Flex>
          <Flex justify="between" slot="actions">
            <Flex>
              {(getAppliedFilters(watchFields) > 0 || isStatusDirty || isOwnershipDirty) && (
                <WppActionButton variant="primary" onClick={onReset}>
                  <WppIconReset className={styles.resetIcon} />
                  {t('common.btn_reset')}
                </WppActionButton>
              )}
            </Flex>
            <Flex gap={12}>
              <WppButton variant="secondary" size="m" onClick={onClose}>
                {t('modals.dashboard_filters.btn_cancel')}
              </WppButton>
              <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
                {t('common.btn_apply')}
              </WppButton>
            </Flex>
          </Flex>
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showProjectFilterModal } = createNiceModal<Props>(ProjectFilterModal, 'project-filter-modal')
