import { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { AppPresentation } from 'pages/projectDemo/components/appPresentation/AppPresentation'
import { ProjectInfo } from 'pages/projectDemo/components/projectInfo/ProjectInfo'
import { SideMenu } from 'pages/projectDemo/components/sideMenu/SideMenu'
import styles from 'pages/projectDemo/ProjectDemo.module.scss'
import { ProjectDemoSkeleton } from 'pages/projectDemo/ProjectDemoSkeleton'
import { DemoItemType, DemoState } from 'pages/projectDemo/utils'
import { ProjectContext } from 'providers/project/ProjectProvider'

export const ProjectDemo = () => {
  const {
    project,
    isProjectLoading,
    canvas: linearData,
    isCanvasLoading: isLinearLoading,
    fluidData,
    isFluidLoading,
    tree,
  } = useContext(ProjectContext)

  const [params] = useSearchParams()

  const [demoState, setDemoState] = useState<DemoState>({ type: DemoItemType.ProjectInfo })

  const isProjectReady = !isProjectLoading && project
  const isCanvasReady = !isLinearLoading && !isFluidLoading

  if (!isProjectReady || !isCanvasReady) {
    return <ProjectDemoSkeleton />
  }

  return (
    <Flex className={styles.container} direction="column" data-testid="demo-page">
      <SideMenu
        linearData={linearData}
        fluidData={fluidData!}
        demoState={demoState}
        setDemoState={setDemoState}
        projectId={project.id}
        selectedAppId={params.get('selectedAppId') || demoState.appId}
        selectedPhaseId={params.get('selectedPhaseId') || demoState.phaseId}
      />

      <div className={styles.infoSection}>
        {demoState.type === DemoItemType.ProjectInfo ? (
          <ProjectInfo project={project} tree={tree} />
        ) : (
          <AppPresentation projectId={project.id} appId={demoState.appId!} />
        )}
      </div>
    </Flex>
  )
}
