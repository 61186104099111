import { WppBanner, WppIconAvailableCheckmark, WppIconArchive } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'pages/project/components/banner/Banner.module.scss'
import { ProjectStatus } from 'types/projects/projects'

interface StyleConfig {
  icon: ReactNode
  title: string
}

const bannerStyles: Record<Exclude<ProjectStatus, ProjectStatus.ACTIVE>, StyleConfig> = {
  [ProjectStatus.COMPLETED]: {
    icon: <WppIconAvailableCheckmark color="var(--wpp-grey-color-000)" />,
    title: 'project.page.project_completed',
  },
  [ProjectStatus.ARCHIVED]: {
    icon: <WppIconArchive color="var(--wpp-grey-color-600)" />,
    title: 'project.page.project_archived',
  },
}

export const Banner = ({ status }: { status: ProjectStatus }) => {
  const { t } = useTranslation()
  const isInactive = status !== ProjectStatus.ACTIVE

  return (
    <WppBanner
      show={isInactive}
      className={clsx(styles.statusBanner, {
        [styles.archivedBanner]: status === ProjectStatus.ARCHIVED,
        [styles.completedBanner]: status === ProjectStatus.COMPLETED,
      })}
      data-testid="project-status-banner"
    >
      {isInactive && bannerStyles[status].icon}
      {isInactive && t(bannerStyles[status].title)}
    </WppBanner>
  )
}
