import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { usePatchFileApi } from 'api/attachments/mutation/usePatchFileApi'
import { tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useToast } from 'hooks/useToast'
import { queryClient } from 'providers/osQueryClient/utils'
import { FilesDTO } from 'types/projects/Files'

export const useTogglePinFile = () => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { mutateAsync: patchFile } = usePatchFileApi()

  const togglePinFile = useCallback(
    async ({ id, name, pinnedAt }: FilesDTO) => {
      const isPinned = !!pinnedAt
      try {
        await patchFile({ id, pin: !isPinned })

        if (isPinned) {
          showToast({ type: 'success', message: t('project.files.pin.unpin_success', { name })! })
        } else {
          showToast({ type: 'success', message: t('project.files.pin.pin_success', { name })! })
        }

        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES_FETCHER])
        tableActions.reload([TableKey.PROJECT_FILE_LIST])

        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES])
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILE, { id }])
      } catch (e) {
        if (isPinned) {
          showToast({ type: 'error', message: t('project.files.pin.unpin_fail', { name })! })
        } else {
          showToast({ type: 'success', message: t('project.files.pin.pin_fail', { name })! })
        }
      }
    },
    [patchFile, showToast, t],
  )

  return { togglePinFile }
}
