import { AttachmentMetadata, MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { Presentation, PresentationItemType } from 'types/projects/projectDemo'

interface CreatePresentation {
  projectId: string
  itemId: string
  subject: PresentationItemType

  content?: MayBeNull<string>
  image?: MayBeNull<AttachmentMetadata>
}

export const createAppPresentationApi = (body: CreatePresentation) =>
  projectApi.post<Presentation>('/presentation-slides', body)
