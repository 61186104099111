import { WppCard, WppTooltip } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import styles from 'pages/project/components/canvas/components/appPikerModal/leanApps/LeanApps.module.scss'
import { LeanAppDTO, LeanAppType } from 'types/products/leanApp'

interface Props {
  leanApp: LeanAppDTO
  isMiroEnabled: boolean
}

export const LeanAppCard = ({ leanApp, isMiroEnabled }: Props) => {
  const { id, name, description, logo } = leanApp
  const { t } = useTranslation()

  // only Miro apps can be disabled here (in case when user has no valid Miro token)
  const isDisabled = leanApp.type === LeanAppType.MIRO_BOARD ? !isMiroEnabled : false

  return (
    <WppCard className={styles.card} value={id} data-testid="application-card" name={name} disabled={isDisabled}>
      <WppTooltip
        className={styles.tooltip}
        header={t('modals.add_from_marketplace.miro_tooltip_disabled.header')!}
        text={t('modals.add_from_marketplace.miro_tooltip_disabled.text')!}
        config={{
          trigger: isDisabled ? 'mouseenter' : 'manual',
          maxWidth: 250,
        }}
      >
        <Flex direction="row" className={styles.content} gap={10}>
          <Flex inline direction="column" className={styles.logo} justify="center" align="center">
            <ApplicationLogo logo={logo?.url} />
          </Flex>

          <Flex direction="column" style={{ flexGrow: 1 }}>
            <Flex justify="between" align="start">
              <Truncate lines={1} type="m-strong" className={styles.name} data-testid="product-name">
                {name}
              </Truncate>
            </Flex>
            {!!description && (
              <Truncate type="s-body" lines={2} className={styles.description} data-testid="product-description">
                {description}
              </Truncate>
            )}
          </Flex>
        </Flex>
      </WppTooltip>
    </WppCard>
  )
}
