import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { usePatchActivityLinkApi } from 'api/canvas/mutation/usePatchActivityLinkApi'
import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/components/item/activity/AppActivityItem.module.scss'
import { externalLink, invalidateCanvas } from 'pages/project/components/canvas/components/item/utils'
import { ExternalLink } from 'types/projects/workflow'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface BaseProps extends NiceModalWrappedProps {
  activityId: string
  link: ExternalLink
}

const validationSchema = zod
  .object({
    externalLink: externalLink,
  })
  .passthrough()

const EditLinkModal = ({ activityId, link, isOpen, onClose, onCloseComplete }: BaseProps) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { mutateAsync: patchActivityLink } = usePatchActivityLinkApi()
  const form = useForm({ defaultValues: { externalLink: link }, validationSchema })

  const {
    handleSubmit,
    formState: { isValid },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      const { externalLink } = values
      await patchActivityLink({ activityId, link: { id: link.id, ...externalLink } })
      invalidateCanvas()
      showToast({ type: 'success', message: t('project.links_modal.toast_success_updated')! })
      onClose()
    } catch (e) {
      console.error(e)
      showToast({ type: 'error', message: t('project.links_modal.toast_error_save_message')! })
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        size="m"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('project.links_modal.edit_title')}
        </WppTypography>

        <Flex direction="column" slot="body" gap={24}>
          <Flex gap={12} direction="column">
            <FormInput
              name="externalLink.alias"
              className={styles.grow}
              labelConfig={{ text: t('project.links_modal.field_name') }}
              placeholder="Name your URL"
              data-testid="add-activity-external-link-name"
            />
            <FormInput
              name="externalLink.url"
              className={styles.grow}
              labelConfig={{ text: t('project.links_modal.field_url') }}
              placeholder="eg. https://www.website..."
              data-testid="add-activity-external-link-url"
              required
            />
          </Flex>
        </Flex>
        <Flex slot="actions" gap={12} justify="end">
          <WppButton size="m" variant="secondary" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton size="m" type="submit" disabled={!isValid} variant="primary">
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditLinkModal } = createNiceModal<BaseProps>(EditLinkModal, 'edit-link-modal')
