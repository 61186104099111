import { MayBeNull } from '@wpp-open/core'
import { AttachmentMetadata } from '@wpp-open/core/types/attachments'

export interface ProjectMember {
  id: string
  email: string
  firstname: string
  lastname: string
  jobTitle: MayBeNull<string>
  agency: string
  sourceType?: string
  officeLocation?: MayBeNull<string>
  department?: MayBeNull<string>
  avatarUrl?: MayBeNull<string>
  avatarThumbnail?: MayBeNull<AttachmentMetadata>
  avatarOriginal?: MayBeNull<AttachmentMetadata>
  active?: boolean
  jobfamilies?: string[]
  skills?: string[]
  linkedinURL?: string
}

export enum ProjectMemberType {
  CONTRIBUTOR = 'CONTRIBUTOR',
}

export interface ExternalMember {
  id: string
  email: string
  firstName: string
  lastName: string
  agency: string
  sourceType?: string
  officeLocation?: MayBeNull<string>
  department?: MayBeNull<string>
  avatarUrl?: MayBeNull<string>
  avatarThumbnail?: MayBeNull<AttachmentMetadata>
  avatarOriginal?: MayBeNull<AttachmentMetadata>
  active?: boolean
  jobfamilies?: string[]
  skills?: string[]
  linkedinURL?: string
}
