import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppButton,
  WppCard,
  WppIconDatabase,
  WppIconDocument,
  WppIconFileZip,
  WppIconImage,
  WppIconMusic,
  WppIconPitch,
  WppIconStatisticDocument,
  WppIconVideoClip,
  WppInput,
  WppTypography,
  WppSkeleton,
  WppSpinner,
} from '@platform-ui-kit/components-library-react'
import { RefCallback, cloneElement, memo, useCallback, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAddActivityFilesApi } from 'api/canvas/mutation/useAddActivityFilesApi'
import { useProjectFilesInfiniteApi } from 'api/projects/queries/useProjectFilesInfiniteApi'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormRadioGroup } from 'components/form/formRadioGroup/FormRadioGroup'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { SvgEmptySearch } from 'components/svg/SvgEmptySearch'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useUploadProjectFilesApi } from 'hooks/attachments/useUploadProjectFilesApi'
import { useForm } from 'hooks/form/useForm'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/components/attachFilesModal/AttachFilesModal.module.scss'
import { invalidateCanvas } from 'pages/project/components/canvas/components/item/utils'
import { FileFormatMapByCategory, FileTypes, mimeToExtensionMapping } from 'pages/project/components/files/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { FilesDTO } from 'types/projects/Files'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { CustomError, is409Error } from 'utils/error'
import { bytes, formatBytes } from 'utils/files'

const FileIcons: Record<FileTypes, JSX.Element> = {
  music: <WppIconMusic height={24} width={24} />,
  compressed: <WppIconFileZip height={24} width={24} />,
  data: <WppIconDatabase height={24} width={24} />,
  images: <WppIconImage height={24} width={24} />,
  presentations: <WppIconPitch height={24} width={24} />,
  spreadsheet: <WppIconStatisticDocument height={24} width={24} />,
  videos: <WppIconVideoClip height={24} width={24} />,
  documents: <WppIconDocument height={24} width={24} />,
}

const getFileIcon = (extension: string, iconColor: string) => {
  const category = FileFormatMapByCategory[extension] || 'documents'
  const Icon = FileIcons[category]
  return cloneElement(Icon, { color: iconColor })
}

const FileCard = memo(({ file, isSelected }: { file: FilesDTO; isSelected?: boolean }) => {
  const name = (file?.name || '').replace(/\.[^/.]+$/, '')
  const extension = file?.name.match(/\.[^.]+$/)![0] || file?.fileType!
  const iconColor = isSelected ? 'var(--wpp-primary-color-500)' : 'var(--wpp-grey-color-600)'

  const Icon = getFileIcon(extension, iconColor)

  return (
    <WppCard value={file.id} data-testid="files-card">
      <Flex direction="row" align="center" gap={12}>
        <Flex className={styles.iconWrapper} align="center">
          {Icon}
        </Flex>
        <Flex direction="column" gap={0}>
          <WppTypography type="s-strong">{name}</WppTypography>
          <WppTypography type="xs-body" className={styles.fileExt}>
            {extension.substring(1)}
          </WppTypography>
        </Flex>
      </Flex>
    </WppCard>
  )
})

interface BaseProps extends NiceModalWrappedProps {
  projectId: string
  activityId: string
}

interface BaseFormValues {
  projectFilesIds: string[]
  files: File[]
}

const defaultValues: BaseFormValues = {
  projectFilesIds: [],
  files: [],
}

const AttachFilesModal = ({ isOpen, projectId, activityId, onClose, onCloseComplete }: BaseProps) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const [search, setSearch] = useState('')
  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])
  const { mutateAsync: addActivityFiles } = useAddActivityFilesApi()

  const [hasError, setError] = useState(false)

  const form = useForm({ defaultValues })

  const {
    data: activityFiles,
    response,
    isLoading: isFilesLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useProjectFilesInfiniteApi({
    params: {
      itemsPerPage: 10,
      search: search.trim().length >= 2 ? search.trim() : undefined,
      projectId,
    },
  })

  const isFetching = isRefetching || isFetchingNextPage
  const resultsNumber = response?.pages?.[response.pages.length - 1].data?.paginator?.totalItems || 0

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const isEmpty = useMemo(
    () => !search.length && !isFilesLoading && !resultsNumber,
    [search.length, isFilesLoading, resultsNumber],
  )

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = form

  const setSearchDebounced = useDebounceFn(
    (e: WppInputCustomEvent<InputChangeEventDetail>) => setSearch(e.detail.value || ''),
    300,
  )

  const handleUploadTenantsFiles = useUploadProjectFilesApi()

  const onSubmit = handleSubmit(async values => {
    try {
      const { projectFilesIds, files: loadedFiles } = values

      const files = !!loadedFiles.length ? await handleUploadTenantsFiles(loadedFiles, projectId) : []

      await addActivityFiles({ files, projectFilesIds, activityId })
      invalidateCanvas()
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES_FETCHER])
      tableActions.reload([TableKey.PROJECT_FILE_LIST])
      showToast({ type: 'success', message: t('project.attach_files_modal.file_added') })
      onClose()
    } catch (e) {
      const is409 = is409Error(e)
      const CODE = (e as any).response?.data?.detail?.code
      console.error(e)

      if (is409 && CODE === CustomError.ITEM_EXISTS) {
        showToast({ type: 'warning', message: t('project.attach_files_modal.files_was_added_error')! })
        return
      }

      showToast({ type: 'error', message: t('project.files.toast_error_add_message')! })
    }
  })

  // @TODO: Move this to utils, using twice
  const fixLocalMsg = useCallback(
    (_: string, size: number) => `Maximum file size for upload is ${formatBytes(bytes(size, 'mb'))}.`,
    [],
  )

  const { files, projectFilesIds } = watch()
  const filesCount = projectFilesIds.length + files.length

  const handleClose = () => {
    if (!isSubmitting) onClose()
  }

  return (
    <FormProvider {...form}>
      <SideModal
        size="m"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={handleClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid="attach-files-modal"
      >
        <WppTypography slot="header" type="2xl-heading" data-testid="add-app-modal-name">
          {t('project.attach_files_modal.title')}
        </WppTypography>

        <Flex direction="column" slot="body" gap={24} style={{ height: '100%' }}>
          <Flex direction="column" gap={8}>
            {!isEmpty && (
              <WppTypography type="xs-midi" className={styles.greyColor800}>
                {t('project.attach_files_modal.description')}
              </WppTypography>
            )}
            <FormFileUpload
              name="files"
              size={100}
              maxLabelLength={45}
              locales={{ info: fixLocalMsg }}
              acceptConfig={mimeToExtensionMapping}
              onWppChange={e => setError(e.detail.hasError)}
              multiple={true}
            />
          </Flex>
          <Flex direction="column" gap={16}>
            {!isEmpty && (
              <>
                <WppTypography type="m-strong" data-testid="file-search-label" className={styles.label}>
                  {t('project.attach_files_modal.files_search_label')}
                </WppTypography>
                <WppInput
                  value={search}
                  size="s"
                  type="search"
                  data-testid="file-search-field"
                  placeholder={t('project.attach_files_modal.files_search_placeholder')}
                  required
                  onWppChange={setSearchDebounced}
                  className={styles.labelColor}
                />
              </>
            )}
            {isFilesLoading ? (
              <Skeleton />
            ) : resultsNumber ? (
              <FormRadioGroup className={styles.radioGroup} multiple withRadioOrCheckbox={false} name="projectFilesIds">
                {activityFiles.map(file => (
                  <FileCard key={file.key} file={file} isSelected={projectFilesIds?.includes(file.id)} />
                ))}

                <Flex justify="center" ref={setRef} className={styles.nextPageSpinner}>
                  {isFetching && <WppSpinner size="m" />}
                </Flex>
              </FormRadioGroup>
            ) : (
              <>
                {!isEmpty && (
                  <Flex justify="center" align="center" style={{ height: '100%' }}>
                    <Flex
                      direction="column"
                      align="center"
                      gap={12}
                      data-testid="not-files-view"
                      className={styles.noResult}
                    >
                      <SvgEmptySearch />
                      <WppTypography type="m-strong">
                        {t('project.list.empty_state_no_search_results', { query: search })}
                      </WppTypography>
                    </Flex>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Flex>
        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" size="m" onClick={handleClose} disabled={isSubmitting}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton
            className={styles.addFilesBtn}
            size="m"
            type="submit"
            loading={isSubmitting}
            disabled={!filesCount || hasError}
            variant="primary"
          >
            {t('project.attach_files_modal.fileCount', { count: filesCount })}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

const Skeleton = () => (
  <Flex direction="column" gap={10}>
    <WppSkeleton variant="rectangle" height={70} />
    <WppSkeleton variant="rectangle" height={70} />
    <WppSkeleton variant="rectangle" height={70} />
    <WppSkeleton variant="rectangle" height={70} />
    <WppSkeleton variant="rectangle" height={70} />
  </Flex>
)

export const { showModal: showAttachFilesModal } = createNiceModal<BaseProps>(AttachFilesModal, 'attach-files-modal')
