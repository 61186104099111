import {
  WppTypography,
  WppIconMail,
  WppIconOrganization,
  WppDivider,
  WppButton,
  WppLabel,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUpdateProjectMemberApi } from 'api/projects/mutation/useUpdateProjectMemberApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/members/components/memberModal/MemberModal.module.scss'
import { roleOption } from 'pages/project/components/members/components/utils'
import { MemberSourceType } from 'pages/project/components/members/utils'
import { useExternalMembers } from 'pages/project/hooks/useExternalMembers/useExternalMembers'
import { Members } from 'types/members/members'
import { Project } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  member: Members
  project: Project
  allOwnersSelected: boolean
  memberType?: MemberSourceType
}

const MemberModal = ({
  member,
  onClose,
  isOpen,
  project,
  allOwnersSelected,
  onCloseComplete,
  memberType = MemberSourceType.Internal,
}: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const isInternalMember = memberType === MemberSourceType.Internal

  const queryClient = useQueryClient()

  const { mutateAsync: updateProjectMember } = useUpdateProjectMemberApi()
  const { updateExternalMember } = useExternalMembers(project.id)

  const form = useForm({ defaultValues: { role: member?.role } })
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async data => {
    const { role } = data

    if (role === member?.role) {
      onClose()
      return
    }

    try {
      isInternalMember
        ? await updateProjectMember({ role, memberId: member.id })
        : updateExternalMember({ ...member, role })
      await queryClient.invalidateQueries([ApiQueryKeys.MEMBERS])

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.members.title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={36}>
          <Flex gap={20}>
            <Avatar
              size="xl"
              name={[member?.firstname, member?.lastname].join(' ').trim()}
              src={member?.avatarUrl ?? ''}
            />
            <Flex direction="column" justify="center">
              <WppTypography type="l-midi">{[member?.firstname, member?.lastname].join(' ').trim()}</WppTypography>
              <WppTypography type="s-body">{member?.jobTitle || ''}</WppTypography>
            </Flex>
          </Flex>
          <Flex direction="column" gap={24}>
            <Flex gap={20} align="center">
              <Flex direction="column" justify="center" align="center" className={styles.icon}>
                <WppIconMail />
              </Flex>
              <Flex direction="column">
                <WppTypography type="xs-midi">{t('modals.members.email')}</WppTypography>
                <WppTypography type="s-body">{member?.email}</WppTypography>
              </Flex>
            </Flex>
            <Flex gap={20} align="center">
              <Flex direction="column" justify="center" align="center" className={styles.icon}>
                <WppIconOrganization />
              </Flex>
              <Flex direction="column">
                <WppTypography type="xs-midi">{t('modals.members.opCo')}</WppTypography>
                <WppTypography type="s-body">{member?.agency || ''}</WppTypography>
              </Flex>
            </Flex>
          </Flex>
          <WppDivider />
          <Flex direction="column" gap={15}>
            <Flex direction="column">
              <WppTypography type="2xs-strong" className={styles.subTitle}>
                {t('modals.members.project')}
              </WppTypography>
              <WppTypography type="xl-heading">{project.name}</WppTypography>
            </Flex>

            <Flex direction="column" gap={6}>
              <WppLabel
                config={{
                  text: 'Access',
                  ...(allOwnersSelected
                    ? {
                        icon: 'wpp-icon-info',
                        description: t('modals.members.all_owners_selected')!,
                      }
                    : {}),
                }}
                htmlFor="role"
                tooltipConfig={{ trigger: allOwnersSelected ? 'mouseenter' : 'manual' }}
                typography="s-strong"
              />
              <FormSelect disabled={allOwnersSelected} required name="role" options={roleOption} />
            </Flex>
          </Flex>
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showMemberModal } = createNiceModal<Props>(MemberModal, 'member-modal')
