import { useEffect, ReactNode, useState } from 'react'

import { useTaxonomyApi } from 'api/tenant/queries/useTaxonomyApi'
import i18n from 'i18n/i18n'
import { capitalizeFirstLetter } from 'utils/common'

export const TaxonomyProvider = ({ children }: { children: ReactNode }) => {
  //TODO: once useOs hook will be extended with taxonomy - use it
  const { data: taxonomy, isFetched } = useTaxonomyApi()
  const [isTaxonomyInjected, setIsTaxonomyInjected] = useState(false)

  useEffect(() => {
    if (taxonomy && i18n.options.interpolation) {
      i18n.options.interpolation.defaultVariables = Object.entries(taxonomy).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
          [capitalizeFirstLetter(key)]: capitalizeFirstLetter(value),
        }),
        {},
      )
    }

    isFetched && setIsTaxonomyInjected(true)
  }, [taxonomy, isFetched])

  return isTaxonomyInjected && children
}
