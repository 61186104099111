import { WppCardGroupCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { CardGroupChangeEventDetail } from '@platform-ui-kit/components-library/dist/types/components/wpp-card-group/types'
import { WppCardGroup, WppSpinner } from '@platform-ui-kit/components-library-react'
import { RefCallback, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteLeanAppsApi } from 'api/applications/queries/useInfiniteLeanAppsApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { AppType } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { LeanAppCard } from 'pages/project/components/canvas/components/appPikerModal/leanApps/LeanAppCard'
import styles from 'pages/project/components/canvas/components/appPikerModal/leanApps/LeanApps.module.scss'
import { SelectedApp } from 'pages/project/components/canvas/components/appPikerModal/types'
import { useIsMyMiroEnabled } from 'pages/project/hooks/useIsMyMiroEnabled'
import { LeanAppDTO } from 'types/products/leanApp'
import { CanvasApplicationType } from 'types/projects/workflow'

interface Props {
  search: string
  selectedApps: SelectedApp[]
  onSelectApp: (args: SelectedApp[]) => void
}

export const LeanApps = ({ search, selectedApps, onSelectApp }: Props) => {
  const { t } = useTranslation()

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  const isMiroEnabled = useIsMyMiroEnabled()
  const {
    data: leanApps,
    response,
    isLoading: isLoadingLeanApps,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteLeanAppsApi({
    params: { search },
  })

  const isFetching = isRefetching || isFetchingNextPage
  const resultsNumber = response?.pages?.[response.pages.length - 1].data?.paginator?.totalItems || 0

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const handleSelectApp = (event: WppCardGroupCustomEvent<CardGroupChangeEventDetail>) => {
    const selectedLeanIds = event.target.value as string[]

    const addedApps: SelectedApp[] = selectedLeanIds.map(leanAppId => {
      // search selected ids first in selected registry, if that's new ID - in actual apps list
      const selectedLeanApp = (selectedApps.find(({ id }) => id === leanAppId) ||
        leanApps.find(({ id }) => id === leanAppId))!

      const logo = (selectedLeanApp as LeanAppDTO).logo?.url || (selectedLeanApp as SelectedApp).logo
      return {
        id: selectedLeanApp.id,
        type: selectedLeanApp.type as unknown as CanvasApplicationType,
        name: selectedLeanApp.name,
        appType: AppType.EMBEDDED,
        logo,
      }
    })

    onSelectApp(addedApps)
  }

  return isLoadingLeanApps ? (
    <Flex justify="center">
      <WppSpinner size="m" />
    </Flex>
  ) : !resultsNumber ? (
    <EmptyState
      message={{
        title: t(!!search.length ? 'product.no_results.title_text' : 'product.no_products.title', {
          query: search,
        })!,
      }}
      showImage={true}
      data-testid="no-results"
      className={styles.emptyState}
    />
  ) : (
    <>
      <WppCardGroup
        multiple
        value={selectedApps.map(({ id }) => id)}
        className={styles.grid}
        onWppChange={handleSelectApp}
      >
        {leanApps.map(leanApp => (
          <LeanAppCard key={leanApp.id} leanApp={leanApp} isMiroEnabled={isMiroEnabled} />
        ))}

        <Flex justify="center" ref={setRef} className={styles.nextPageSpinner}>
          {isFetching && <WppSpinner size="m" />}
        </Flex>
      </WppCardGroup>
    </>
  )
}
