import { WppTag, WppIconSuccess, WppIconArchive } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { ProjectStatus as Status } from 'types/projects/projects'

interface StyleConfig {
  icon: ReactNode
  label: string
  variant: 'neutral' | 'positive'
}

const styleConfig: Record<Exclude<Status, Status.ACTIVE>, StyleConfig> = {
  [Status.ARCHIVED]: {
    icon: <WppIconArchive slot="icon-start" />,
    label: 'project.status.archived',
    variant: 'neutral',
  },
  [Status.COMPLETED]: {
    icon: <WppIconSuccess slot="icon-start" />,
    label: 'project.status.completed',
    variant: 'positive',
  },
}

export const ProjectStatusTag = ({ status, className }: { status: keyof typeof Status; className?: string }) => {
  const { t } = useTranslation()

  return status !== Status.ACTIVE ? (
    <WppTag label={t(styleConfig[status].label)} variant={styleConfig[status].variant} className={className}>
      {styleConfig[status].icon}
    </WppTag>
  ) : null
}
