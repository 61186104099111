import {
  WppActionButton,
  WppCard,
  WppIconGear,
  WppIconMore,
  WppIconRemoveCircle,
  WppMenuContext,
  WppListItem,
  WppTypography,
  WppDivider,
  WppIconApp,
  WppIconPlus,
  WppIconDocument,
  WppIconLink,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { PropsWithChildren, useContext, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'

import { useDeleteActivityApi } from 'api/canvas/mutation/useDeleteActivityApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useAssignMember } from 'hooks/useAssignMember'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useToast } from 'hooks/useToast'
import { showAddLinksModal } from 'pages/project/components/canvas/components/addLinksModal/AddLinksModal'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { AttachedFile } from 'pages/project/components/canvas/components/attachedFile/AttachedFile'
import { showAttachFilesModal } from 'pages/project/components/canvas/components/attachFilesModal/AttachFilesModal'
import styles from 'pages/project/components/canvas/components/item/activity/AppActivityItem.module.scss'
import { DragActivityItem } from 'pages/project/components/canvas/components/item/activity/DragActivityItem'
import { ExternalLinkView } from 'pages/project/components/canvas/components/item/ExternalLinkView'
import { MAX_LINKS_COUNT, useUiPartEnabled } from 'pages/project/components/canvas/components/item/utils'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import { Placeholder } from 'pages/project/components/canvas/components/placeholder/Placeholder'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import { LinearDispatchContext } from 'pages/project/components/canvas/LinearProvider'
import { DragContainerType, DnDItem } from 'pages/project/components/canvas/utils'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { ProjectRole, AppPermissions } from 'types/permissions/permissions'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, ExternalLink, PhaseItemType } from 'types/projects/workflow'
import { isEqualEmails, makeStringShorter } from 'utils/common'

type Props = PropsWithChildren<{
  activityItem: ActivityItem
  showAction: boolean
  isEditable: boolean
  isDisabled: boolean
  isInactive: boolean
  index: number
  changeAssignee: (newAssignee: ResponsibleUser) => Promise<void>
  changeDates: (dates: Date[]) => void
  changeStatus: (status: TaskStatus) => void
  editActivity?: () => void
  deleteActivity?: () => void
  phaseItemId: string
  variant?: 'primary' | 'secondary'
  phaseId: string
  preview?: boolean
  sortedExternalLinks?: ExternalLink[]
  isDraggingDisabled?: boolean
  isIAssignToThisPhase?: boolean
}>

export const EditableActivity = ({
  activityItem,
  showAction,
  isEditable,
  isDisabled: isDisabledProp,
  isInactive,
  index,
  variant,
  preview,
  phaseId,
  phaseItemId,
  sortedExternalLinks,
  isIAssignToThisPhase,
  isDraggingDisabled,
  children,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  const { changeDates, editActivity, changeAssignee, changeStatus } = rest
  const { name, startDate, endDate, id, task, files, description } = activityItem
  const { viewSettings, project } = useContext(ProjectContext)
  const { dropOnActivity } = useContext(LinearDispatchContext)
  const [placeholderHeight, setPlaceholderHeight] = useState<MayBeNull<number>>(null)
  const isDisabled = Boolean(isDisabledProp || isInactive)

  const assignMember = useAssignMember(activityItem.assignUser)
  const isUIPartEnabled = useUiPartEnabled(viewSettings, isEditable)

  const {
    osContext: { userDetails },
  } = useOs()

  const { isPermitted } = useIsPermitted()
  const { hasRole } = useHasProjectRole()
  const isOwner = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const isIAssignToThisActivity = isEqualEmails(activityItem?.assignUser, userDetails.email)

  const dropDisable =
    isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE) || isIAssignToThisActivity || isIAssignToThisPhase
      ? false
      : !isOwner

  const { showToast } = useToast()
  const { mutateAsync: handleDeleteActivity } = useDeleteActivityApi()

  const handleDelete = async () => {
    try {
      await handleDeleteActivity({ id })
      showToast({
        type: 'success',
        message: t('project.canvas.toast.remove_activity', { query: makeStringShorter(activityItem.name) }),
      })

      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    } catch (e) {
      showToast({ type: 'error', message: t('project.canvas.toast.failed_operation_remove', { query: 'activity' }) })
      console.error(e)
    }
  }

  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: DragContainerType.Task,
      canDrop(item: DnDItem) {
        if (dropDisable) {
          return false
        }

        return item.type === PhaseItemType.Application
      },
      hover(dndItem: DnDItem) {
        setPlaceholderHeight(dndItem.height ?? null)
      },
      drop(dndItem: DnDItem, monitor) {
        const didDrop = monitor.didDrop()
        if (didDrop) return
        dropOnActivity(phaseItemId, dndItem)
      },
      collect: monitor => ({
        isOverCurrent: monitor.isOver({ shallow: true }) && monitor.canDrop(),
      }),
    }),
    [dropOnActivity, phaseItemId],
  )

  return (
    <>
      <WppCard
        variant={variant}
        data-testid="activity-card"
        size="s"
        className={clsx(styles.itemContainer, {
          [styles.dragItem]: !isDisabled && !isInactive && !isDraggingDisabled,
        })}
      >
        <Flex direction="column" className={styles.nameWrapper} gap={8}>
          <Flex align="center" justify="between">
            <Truncate
              lines={2}
              type="m-strong"
              title={name}
              data-testid="phase-item-name"
              className={clsx({ [styles.disabledItem]: isDisabled })}
            >
              {name}
            </Truncate>

            <Flex align="center" className={styles.alignSelf}>
              {showAction && (
                <WppMenuContext key={index} dropdownConfig={{ appendTo: () => document.body }}>
                  <WppActionButton slot="trigger-element" variant="secondary">
                    <WppIconMore slot="icon-start" direction="horizontal" />
                  </WppActionButton>
                  <WppListItem onWppChangeListItem={editActivity} data-testid="context-settings">
                    <WppIconGear slot="left" />
                    <WppTypography slot="label" type="s-body">
                      {t('project.canvas.settings')}
                    </WppTypography>
                  </WppListItem>

                  {/* DO_NOT_DELETE: ternary operator coz error: Can't remove ReactNode. More like Tippy issue, remove node firstly */}
                  <div style={{ display: isInactive ? 'none' : 'block' }}>
                    <WppListItem
                      onWppChangeListItem={() =>
                        showDeleteModal({
                          title: t('modals.remove_activity.delete_item'),
                          subTitle: t('modals.remove_activity.delete_confirm')!,
                          deleteText: t('common.btn_delete')!,
                          onDelete: handleDelete,
                        })
                      }
                      data-testid="context-remove"
                    >
                      <WppIconRemoveCircle slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('project.canvas.delete')}
                      </WppTypography>
                    </WppListItem>
                    <WppDivider />
                    <TaskStatusChangeDropdown
                      onChange={status => changeStatus(status)}
                      selectedStatus={task?.status}
                      showConfirm={false}
                    />
                  </div>
                </WppMenuContext>
              )}
            </Flex>
          </Flex>

          {description && (
            <WppTypography type="xs-body" data-testid="activity-description" className={styles.greyColor800}>
              {description}
            </WppTypography>
          )}

          <Flex direction="column" gap={8}>
            {(task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED) && (
              <StatusText
                statusKey={task?.status}
                data-testid="activity-task-status"
                className={clsx({ [styles.disabledItem]: isDisabled })}
              />
            )}
            <Flex
              gap={!showAction || isInactive ? 10 : 0}
              align="center"
              className={clsx({ [styles.disabledItem]: isDisabled })}
            >
              {showAction && !isInactive ? (
                <Flex gap={4} align="center">
                  <SelectPersonInline selectedId={assignMember?.id} onChange={changeAssignee}>
                    <ResponsiblePerson assignMember={assignMember} size="xs" />
                  </SelectPersonInline>

                  <SelectDateInline startDate={startDate} endDate={endDate} onChange={changeDates} />
                </Flex>
              ) : (
                <>
                  {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && (
                    <ResponsiblePerson assignMember={assignMember} data-testid="phase-item-assignee" size="xs" />
                  )}
                  {isUIPartEnabled(ProjectPartKey.Dates) && (
                    <Calendar startDate={startDate} endDate={endDate} data-testid="phase-item-dates" />
                  )}
                </>
              )}
              {showAction && (
                <div className={clsx(styles.addMenuWrapper, { [styles.isDisabled]: isInactive })}>
                  <WppMenuContext data-testid="context-add">
                    <WppActionButton slot="trigger-element" disabled={isInactive}>
                      <WppIconPlus slot="icon-start" />
                      {t('project.canvas.btn_add_item')}
                    </WppActionButton>
                    <div>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showAppPickerModal({
                            projectId: project.id,
                            selectedCanvas: project.processType,
                            activityId: id,
                          })
                        }
                        data-testid="context-add-app"
                      >
                        <WppIconApp slot="left" />
                        <p slot="label">{t('project.canvas.application')}</p>
                      </WppListItem>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showAttachFilesModal({ projectId: project.id, activityId: activityItem.id })
                        }
                        data-testid="context-add-file"
                      >
                        <WppIconDocument slot="left" />
                        <p slot="label">{t('project.canvas.files')}</p>
                      </WppListItem>
                      <WppListItem
                        disabled={(sortedExternalLinks?.length ?? 0) >= MAX_LINKS_COUNT}
                        onWppChangeListItem={() =>
                          showAddLinksModal({
                            projectId: project.id,
                            activityId: activityItem.id,
                            existingCount: sortedExternalLinks?.length ?? 0,
                          })
                        }
                      >
                        <WppIconLink slot="left" />
                        <p slot="label">{t('project.canvas.link')}</p>
                      </WppListItem>
                    </div>
                  </WppMenuContext>
                </div>
              )}
            </Flex>
          </Flex>
        </Flex>
        <WppDivider className={styles.divider} />
        {!!sortedExternalLinks?.length && (
          <Flex direction="column" gap={4} className={styles.linksWrapper} data-testid="activity-links">
            <ShowMoreItems maxToShow={2}>
              {sortedExternalLinks.map(link => (
                <ExternalLinkView
                  activityId={activityItem.id}
                  link={link}
                  isDisabled={isDisabled}
                  key={link.id}
                  isInactive={isInactive}
                  linkActions={showAction}
                />
              ))}
            </ShowMoreItems>
          </Flex>
        )}
        {!!files?.length && (
          <Flex direction="column" gap={4} className={styles.cardWrapper} data-testid="activity-files">
            <ShowMoreItems maxToShow={2}>
              {files.map(file => (
                <AttachedFile
                  file={file}
                  key={file.id}
                  activityId={activityItem.id}
                  isDisabled={isDisabled}
                  showAction={showAction}
                  isInactive={isInactive}
                />
              ))}
            </ShowMoreItems>
          </Flex>
        )}
        <Flex
          ref={drop}
          direction="column"
          gap={12}
          className={styles.droppableStyle}
          data-id="activity-droppable-container"
          data-testid="activity-droppable-container"
        >
          {activityItem.items?.map((t, i) => {
            return (
              <DragActivityItem
                activityApplicationItem={t}
                isEditable={isEditable}
                index={i}
                isInactive={isInactive}
                variant="primary"
                projectId={project.id}
                key={t.id}
                phaseId={phaseId}
                isIAssignToThisActivity={isIAssignToThisActivity}
                isIAssignToThisPhase={isIAssignToThisPhase}
              />
            )
          })}
          {isOverCurrent && <Placeholder className={styles.placeholder} height={placeholderHeight} />}
        </Flex>
      </WppCard>
    </>
  )
}
