import {
  WppActionButton,
  WppIconMore,
  WppIconRemoveCircle,
  WppListItem,
  WppMenuContext,
  WppTypography,
  WppIconGear,
  WppIconEnter,
  WppDivider,
  WppCheckbox,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteApplicationApi } from 'api/canvas/mutation/useDeleteApplicationApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useCheckCaasRequirements } from 'hooks/application/useCheckCaasRequirements'
import { useToast } from 'hooks/useToast'
import { showEditAppModal } from 'pages/project/components/canvas/components/item/application/EditAppModal'
import styles from 'pages/project/components/canvas/components/item/ManageItemMenu.module.scss'
import { useHighligtPreferences } from 'pages/project/components/canvas/hooks/useHighlightPreferences'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem } from 'types/projects/workflow'
import { makeStringShorter } from 'utils/common'

interface Props {
  application: ApplicationItem
  handleOpenApp?: () => void
  isOutdated?: boolean
  updateStatus: (status: TaskStatus) => void
  isInactive?: boolean
  isHighlighted?: boolean
}

export const ManageItemMenu = ({
  application,
  handleOpenApp,
  isOutdated,
  updateStatus,
  isInactive,
  isHighlighted,
}: Props) => {
  const { t } = useTranslation()

  const { project, highlightPreferences } = useContext(ProjectContext)
  const { isCaasAppHavePermission, isCaasHasCurrentWorkspace } = useCheckCaasRequirements(application)
  const { mutateAsync: handleDeleteApplication } = useDeleteApplicationApi()
  const { showToast } = useToast()
  const contextKey = String(crypto.randomUUID())
  const { createHighlightPreference, patchHighlightPreference, deleteHighlightPreference } = useHighligtPreferences({
    projectId: project.id,
  })

  const handleHighlightApp = () => {
    if (!highlightPreferences) {
      createHighlightPreference({
        phaseId: null,
        name: application.name || application.externalAppName,
        applications: [{ id: application.id, name: application.name || application.externalAppName }],
      })
      return
    }
    if (isHighlighted && highlightPreferences) {
      if (highlightPreferences.applications.length === 1 && !highlightPreferences.phaseId) {
        deleteHighlightPreference()
      } else {
        const modifiedHighligtedApps = highlightPreferences.applications.filter(
          highligtedApp => highligtedApp.id !== application.id,
        )
        patchHighlightPreference({
          applications: modifiedHighligtedApps,
        })
      }
      return
    }
    patchHighlightPreference({
      applications: [
        ...highlightPreferences.applications,
        { id: application.id, name: application.name || application.externalAppName },
      ],
    })
  }

  const handleDelete = async () => {
    try {
      await handleDeleteApplication({ id: application.id })
      showToast({
        type: 'success',
        message: t('project.canvas.toast.delete_app', { query: makeStringShorter(application.name) }),
      })
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_operation_remove', { query: 'application' }),
      })
      console.error(e)
    } finally {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
    }
  }

  return (
    <WppMenuContext key={contextKey} slot="actions" dropdownConfig={{ appendTo: () => document.body }}>
      <WppActionButton slot="trigger-element" variant="secondary">
        <WppIconMore slot="icon-start" direction="horizontal" size="s" />
      </WppActionButton>
      <div>
        <WppListItem
          onWppChangeListItem={handleOpenApp}
          disabled={!isCaasAppHavePermission || !isCaasHasCurrentWorkspace || isOutdated}
          data-testid="context-app-launch"
        >
          <WppIconEnter slot="left" />
          <span slot="label">{t('project.item.launch_app')}</span>
        </WppListItem>
        <WppListItem
          onWppChangeListItem={() => showEditAppModal({ application: application })}
          data-testid="context-app-settings"
        >
          <WppIconGear slot="left" />
          <WppTypography slot="label" type="s-body">
            {t('project.item.settings')}
          </WppTypography>
        </WppListItem>
        <div style={{ display: isInactive ? 'none' : 'block' }}>
          <WppListItem
            onWppChangeListItem={() =>
              showDeleteModal({
                title: t('project.canvas.delete_app_title'),
                subTitle: t('project.canvas.delete_app'),
                deleteText: t('project.canvas.delete')!,
                onDelete: handleDelete,
              })
            }
            data-testid="context-app-remove"
          >
            <WppIconRemoveCircle slot="left" />
            <WppTypography slot="label" type="s-body">
              {t('common.btn_delete')}
            </WppTypography>
          </WppListItem>
          <WppDivider className={styles.highlightTopDivider} />
          <WppListItem checked={isHighlighted} onWppChangeListItem={handleHighlightApp}>
            <Flex slot="label" align="center">
              <WppCheckbox
                checked={isHighlighted}
                required
                labelConfig={{ text: t('project.canvas.spotlight') }}
                className={clsx(isHighlighted && styles.highlightedCheckboxLabel)}
              />
            </Flex>
          </WppListItem>
          <WppDivider className={styles.highlightBottomDivider} />
          <TaskStatusChangeDropdown
            onChange={status => updateStatus(status)}
            selectedStatus={application.task?.status}
            showConfirm={false}
          />
        </div>
      </div>
    </WppMenuContext>
  )
}
