// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cXubQ{display:grid;grid-template-columns:1fr 1fr;grid-gap:16px;gap:16px;margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/members/components/externalMemberForm/ExternalMemberForm.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,6BAAA,CACA,aAAA,CAAA,QAAA,CACA,eAAA","sourcesContent":[".externalMembers {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 16px;\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"externalMembers": `cXubQ`
};
export default ___CSS_LOADER_EXPORT___;
