import {
  WppButton,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import React, { useState, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import MemberRecommendations from 'pages/project/components/members/components/inviteMembersModal/memberRecommendations/MemberRecommendations'
import styles from 'pages/project/components/members/components/inviteMembersModal/memberRecommendations/steps/recommendations/Recommendations.module.scss'
import {
  MembersRole,
  SetupStep,
  useMemberRecommendations,
} from 'pages/project/components/members/components/inviteMembersModal/memberRecommendations/utils'
import SearchMembers from 'pages/project/components/members/components/inviteMembersModal/searchMembers/SearchMembers'
import { useSearchMembers } from 'pages/project/components/members/components/inviteMembersModal/searchMembers/utils'
import {
  InviteMembersOptions,
  InviteMembersType,
} from 'pages/project/components/members/components/inviteMembersModal/utils'
import { inviteMembersSchema } from 'pages/project/components/members/components/utils'
import { ProjectRole } from 'types/permissions/permissions'
import { ExternalMember, ProjectMember, ProjectMemberType } from 'types/projects/projectMembers'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface ExternalMemberControl extends ExternalMember {
  id: string
}

const defaultValues = {
  members: [] as ProjectMember[],
  thirdPartyMembers: [] as ProjectMember[],
  externalMembers: [] as ExternalMemberControl[],
  accessType: ProjectMemberType.CONTRIBUTOR,
  message: '',
  role: ProjectRole.VIEWER,
  currentStep: SetupStep.Details,
  client: '',
  brand: '',
  market: '',
  jobFamilies: [],
  skills: [],
  invitationMessage: '',
  membersRole: {} as MembersRole,
}

const InviteMembersModal = ({ onClose, isOpen, onCloseComplete }: NiceModalWrappedProps) => {
  const { t } = useTranslation()

  const [inviteType, setInviteType] = useState(InviteMembersType.SearchMembers)

  const isRecommendationsMode = inviteType === InviteMembersType.MemberRecommendations

  const form = useForm({ defaultValues, validationSchema: inviteMembersSchema })

  const { submitText: submitTextSearchMembers, onSubmit: onSubmitSearchMembers } = useSearchMembers({
    onClose,
    form,
  })

  const {
    submitText: submitTextMemberRecommendations,
    onSubmit: onSubmitMemberRecommendations,
    isDisabledSubmit,
    actionAreaMessage,
    ...recommendationProps
  } = useMemberRecommendations({
    onClose,
    form,
  })

  const {
    reset,
    formState: { isSubmitting },
  } = form

  // NOTE: Temporary solution that allows to hide "Member recommendations" until this feature is fully ready
  const isShowingInviteControl = true

  const isSearchMembersSubmitDisabled = useMemo(() => !form.getValues('members').length, [form])

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit: isRecommendationsMode ? onSubmitMemberRecommendations : onSubmitSearchMembers }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        disableOutsideClick
        size="m"
        data-testid="invite-members-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.invite_member.title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          {isShowingInviteControl && (
            <WppSegmentedControl
              value={inviteType}
              onWppChange={event => {
                setInviteType(event.detail.value as InviteMembersType)
                reset()
              }}
            >
              {InviteMembersOptions.map(option => (
                <WppSegmentedControlItem key={option.value} value={option.value}>
                  {t(option.label)}
                </WppSegmentedControlItem>
              ))}
            </WppSegmentedControl>
          )}
          {isRecommendationsMode ? <MemberRecommendations {...recommendationProps} /> : <SearchMembers />}
        </Flex>

        <Flex slot="actions" direction="row" align="center" justify="between">
          <WppTypography type="s-strong" className={styles.selectedMembersCount}>
            {actionAreaMessage}
          </WppTypography>
          <Flex gap={12}>
            <WppButton variant="secondary" size="m" onClick={onClose}>
              {t('modals.invite_member.btn_cancel')}
            </WppButton>
            <WppButton
              variant="primary"
              size="m"
              type="submit"
              loading={isSubmitting}
              disabled={isRecommendationsMode ? isDisabledSubmit : isSearchMembersSubmitDisabled}
            >
              {isRecommendationsMode ? submitTextMemberRecommendations : submitTextSearchMembers}
            </WppButton>
          </Flex>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showInviteMembersModal } = createNiceModal<NiceModalWrappedProps>(
  InviteMembersModal,
  'invite-members-modal',
)
