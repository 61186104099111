import {
  WppListItem,
  WppTypography,
  WppIconCircle,
  WppIconAvailableCheckmark,
  WppIconSuccess,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { showConfirmModal } from 'components/common/confirmModal/ConfirmModal'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { TaskStatus } from 'types/projects/tasks'

interface Props {
  onChange: (status: TaskStatus) => void
  selectedStatus: TaskStatus
  hideLabel?: boolean
  showConfirm?: boolean
}

// @TODO: refactore
export const TaskStatusChangeDropdown = ({ onChange, selectedStatus, hideLabel, showConfirm = true }: Props) => {
  const { t } = useTranslation()

  const handleChange = (status: TaskStatus) => {
    if (status === TaskStatus.ARCHIVED || selectedStatus === TaskStatus.ARCHIVED) {
      return showConfirmModal({
        title:
          status === TaskStatus.ARCHIVED
            ? t('project.tasks.archive_confirmation_title')
            : t('project.tasks.unarchive_confirmation_title'),
        btnSubmitText: t('common.btn_confirm')!,
        handleSubmit: () => onChange(status),
        confirmMessage:
          status === TaskStatus.ARCHIVED
            ? t('project.tasks.save_archive_confirmation_message')
            : t('project.tasks.save_unarchive_confirmation_message')!,
      })
    }

    onChange(status)
  }

  return (
    <>
      {!hideLabel && (
        <WppTypography type="2xs-strong" className={styles.statusLabel}>
          {t('project.tasks.status_label')}
        </WppTypography>
      )}

      {Object.values(TaskStatus).map(status => (
        <WppListItem
          key={status}
          onWppChangeListItem={() => (showConfirm ? handleChange(status) : onChange(status))}
          data-testid={`context-app-${status}`}
          className={clsx(styles.statusItem)}
          checked={selectedStatus === status}
        >
          {selectedStatus === status ? (
            <>
              {selectedStatus === TaskStatus.COMPLETED ? (
                <WppIconSuccess slot="left" />
              ) : (
                <WppIconAvailableCheckmark slot="left" />
              )}
            </>
          ) : (
            <WppIconCircle slot="left" />
          )}
          <WppTypography
            slot="label"
            type="s-body"
            className={clsx({ [styles.selectedText]: selectedStatus === status })}
          >
            {t(`project.tasks.status.${status}`)!}
          </WppTypography>
        </WppListItem>
      ))}
    </>
  )
}
