import {
  WppButton,
  WppTypography,
  WppDivider,
  WppActionButton,
  WppIconTriangleFill,
  WppMenuContext,
  WppInlineMessage,
} from '@platform-ui-kit/components-library-react'
import { format } from 'date-fns'
import { useEffect, useCallback, useContext, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { usePatchTaskApi } from 'api/canvas/mutation/usePatchTaskApi'
import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { NoRecordsSvg } from 'components/svg/NoRecordsSvg'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { projectDateFormat } from 'pages/components/projectModal/utils'
import { invalidateCanvas } from 'pages/project/components/canvas/components/item/utils'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import {
  getDate,
  normalizeUserObj,
  ResponsibleUser,
} from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import {
  SideModalLoadingSkeleton,
  taskTargetTypeMap,
} from 'pages/project/components/tasks/components/taskDetailsModal/TaskDetailsModal'
import { TaskLocation } from 'pages/project/components/tasks/components/taskDetailsModal/TaskLocation'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectContext } from 'providers/project/ProjectProvider'
import { MayBeNull } from 'types/common/utils'
import { Task, TaskStatus } from 'types/projects/tasks'
import { isEqualEmails } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  task: MayBeNull<Task>
  isLoading: boolean
}

const taskValidationSchema = zod
  .object({
    name: zod
      .string()
      .min(1, 'Task name should contain at least 1 character')
      .max(256, 'Task name exceeded the maximum number of characters'),
    description: zod.string().max(400, 'Description should have 400 characters at most').optional(),
  })
  .passthrough()

interface BaseFormValues {
  assignUser: MayBeNull<ResponsibleUser>
  name?: string
  description?: string
  startDate?: string
  endDate?: string
  status: TaskStatus
}

const defaultValues: BaseFormValues = {
  assignUser: null,
  description: '',
  name: '',
  status: TaskStatus.TO_DO,
}

export const TaskEditForm = ({ task, isLoading, onClose, onCloseComplete, isOpen, id }: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const { members } = useContext(ProjectContext)

  const { mutateAsync: handleUpdateTask } = usePatchTaskApi()

  const form = useForm({ defaultValues, validationSchema: taskValidationSchema })

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValues,
    watch,
    setValue,
  } = form

  const [startDate, endDate, status, assignUser] = watch(['startDate', 'endDate', 'status', 'assignUser'])

  const onUserChange = useCallback(
    (person: ResponsibleUser) => {
      if (isEqualEmails(person.email, assignUser?.email)) {
        setValue('assignUser', null)
      } else {
        setValue('assignUser', person)
      }
    },
    [assignUser?.email, setValue],
  )

  useEffect(() => {
    if (task) {
      const { name, description, startDate, endDate, assignUser, status } = task
      const member = members.find(member => isEqualEmails(member.email, assignUser))
      setValues({
        name,
        assignUser: member ? normalizeUserObj(member) : null,
        description: description || '',
        startDate,
        endDate,
        status,
      })
    }
  }, [members, setValues, task])

  const onSubmit = handleSubmit(async ({ assignUser, startDate, endDate, status, description, name }) => {
    try {
      if (!task) return
      await handleUpdateTask({
        id: task.id,
        assignUser: assignUser?.email || null,
        startDate: startDate || null,
        endDate: endDate || null,
        status,
        description: description?.trim() || null,
        name: name?.trim() || null,
      })

      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TASKS_LIST])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TASK])

      await invalidateCanvas(!assignUser?.isMember)
      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  })

  const handleUpdateDates = (dates: Date[]) => {
    const stringDates = dates.map(date => format(date, projectDateFormat))
    const datesMapped = { ...getDate(stringDates) }

    setValue('startDate', datesMapped.startDate || undefined)
    setValue('endDate', datesMapped.endDate || undefined)
  }

  const showWarningArhivedStatus = useMemo(
    () => task?.status !== TaskStatus.ARCHIVED && status === TaskStatus.ARCHIVED,
    [status, task?.status],
  )

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        id={id}
        size="l"
        data-testid="edit-task-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('project.tasks.details_modal.title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24} className={styles.tasksContainer}>
          {isLoading ? (
            <SideModalLoadingSkeleton />
          ) : (
            <>
              {!task ? (
                <Flex justify="center" align="center" className={styles.noResultsContainer} direction="column" gap={8}>
                  <NoRecordsSvg className={styles.image} />
                  <WppTypography type="m-strong" className={styles.greyColor1000}>
                    {t('project.tasks.details_modal.no_results')}
                  </WppTypography>
                </Flex>
              ) : (
                <>
                  <Flex direction="column" gap={12}>
                    <FormInput
                      name="name"
                      data-testid="task-name-input"
                      labelConfig={{ text: t('project.tasks.edit_modal.task_name_label') }}
                      placeholder={t('project.tasks.edit_modal.task_name_placeholder')!}
                      required
                    />

                    <Flex gap={24} align="center">
                      <Flex align="center">
                        <WppMenuContext data-testid="status-change">
                          <WppActionButton variant="secondary" slot="trigger-element">
                            <WppIconTriangleFill slot="icon-end" className={styles.triangleIcon} />
                            {t(`project.tasks.status.${status}`)}
                          </WppActionButton>
                          <div>
                            <TaskStatusChangeDropdown
                              onChange={status => setValue('status', status)}
                              selectedStatus={status}
                              showConfirm={false}
                            />
                          </div>
                        </WppMenuContext>
                      </Flex>

                      <SelectPersonInline selectedId={assignUser?.id} onChange={onUserChange}>
                        <ResponsiblePerson
                          assignMember={assignUser!}
                          size="xs"
                          data-testid="task-item-assignee"
                          showName
                        />
                      </SelectPersonInline>

                      <SelectDateInline
                        startDate={startDate}
                        endDate={endDate}
                        data-testid="task-item-dates"
                        onChange={handleUpdateDates}
                      />
                    </Flex>
                  </Flex>

                  {showWarningArhivedStatus && (
                    <WppInlineMessage
                      size="m"
                      message={t('project.tasks.edit_modal.archive_information')!}
                      type="information"
                    />
                  )}

                  <Flex direction="column" gap={24}>
                    <FormTextareaInput
                      name="description"
                      labelConfig={{ text: t('project.tasks.edit_modal.task_description_label') }}
                      placeholder={t('project.tasks.edit_modal.task_description_placeholder')}
                      warningThreshold={380}
                      charactersLimit={400}
                      data-testid="modal-phase-description"
                    />
                    <WppDivider />
                    <Flex gap={24} direction="column">
                      <Flex gap={20} align="center">
                        <Flex inline direction="column" className={styles.icon} justify="center" align="center">
                          {taskTargetTypeMap[task.targetType].icon}
                        </Flex>
                        <Flex direction="column">
                          <WppTypography type="xs-midi" className={styles.greyColor800}>
                            {t('project.tasks.details_modal.task_type_label')}
                          </WppTypography>
                          <WppTypography type="s-body">{t(taskTargetTypeMap[task.targetType].name)}</WppTypography>
                        </Flex>
                      </Flex>
                      <TaskLocation location={task.location} />
                    </Flex>
                  </Flex>
                </>
              )}
            </>
          )}
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}
