import { WppInput, WppSkeleton, WppTypography, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useState, RefCallback, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useSearchParams } from 'react-router-dom'

import { useDeleteWorkflowTemplateApi } from 'api/canvas/mutation/useDeleteWorkflowTemplateApi'
import { useFetchWorkflowTemplatesInfiniteApi } from 'api/templates/queries/useFetchWorkflowTemplatesInfiniteApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { NoRecordsSvg } from 'components/svg/NoRecordsSvg'
import { SvgEmptySearch } from 'components/svg/SvgEmptySearch'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useToast } from 'hooks/useToast'
import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import { TemplateCard } from 'pages/components/templateCard/TemplateCard'
import {
  showTemplatePreview,
  useTemplatePreviewModal,
} from 'pages/components/templatePreviewModal/TemplatePreviewModal'
import { DashboardNavigation } from 'pages/dashboard/components/dashboardNavigation/DashboardNavigation'
import { useRemainingPaginationItems } from 'pages/project/hooks/useRemainingPaginationItems'
import styles from 'pages/templates/Templates.module.scss'
import { queryClient } from 'providers/osQueryClient/utils'
import { AppPermissions } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

const NoTemplates = ({ text, image }: { text: string; image: JSX.Element }) => {
  return (
    <Flex className={styles.noData} align="center" justify="center" direction="column" gap={8}>
      {image}
      <WppTypography type="m-strong" data-testid="no-templates-text">
        {text}
      </WppTypography>
    </Flex>
  )
}

export const Templates = () => {
  const { t } = useTranslation()
  const { visible, hide } = useTemplatePreviewModal()

  // @TODO: create hook
  const [params, setParams] = useSearchParams()
  useEffect(() => {
    const { view, id } = Object.fromEntries(params.entries()) as { view: string; id?: string }

    if (view && view === 'template') {
      showTemplatePreview({
        templateId: id,
        handleSubmit: () => {
          showCreateProjectModal({ templatePresetId: id, showProcessType: false })
        },
        onClose: setParams,
      })
    } else visible && hide()
  }, [hide, params, setParams, visible])

  const [search, setSearch] = useState<undefined | string>()

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  const {
    data: templates,
    response,
    isLoading: isTemplatesLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchWorkflowTemplatesInfiniteApi({
    params: {
      inputText: search,
    },
  })

  const isFetching = isRefetching || isFetchingNextPage
  const paginator = response?.pages?.[response.pages.length - 1]?.data?.paginator

  const { itemsRemaining } = useRemainingPaginationItems({
    itemsPerPage: paginator?.itemsPerPage,
    page: paginator?.page,
    totalItems: paginator?.totalItems,
  })

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const { isPermitted } = useIsPermitted()
  const isInternalUser =
    isPermitted(AppPermissions.ORCHESTRATION_PROJECTS_CREATE) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const setSearchDebounced = useDebounceFn((search: string) => {
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setSearch(searchQuery)
  }, 300)

  const { showToast } = useToast()
  const { mutateAsync: handleDeleteTemplate } = useDeleteWorkflowTemplateApi()

  const handleDelete = async (id: string) => {
    try {
      await handleDeleteTemplate({ id })

      await queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])
      await queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES])
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  }

  if (!isInternalUser) {
    return <Navigate to={routesManager.projects.root.getURL()} />
  }

  const message = search ? t('templates.no_search_results', { query: search }) : t('templates.no_data')
  const image = search ? <SvgEmptySearch className={styles.image} /> : <NoRecordsSvg className={styles.image} />

  return (
    <>
      <Flex direction="column" className={styles.container}>
        <div className={styles.headerContainer}>
          <DashboardNavigation />
        </div>
        <Flex className={styles.filtersContainer}>
          <WppInput
            size="s"
            name="search"
            placeholder={t('dashboard.field_search_placeholder')!}
            onWppChange={e => setSearchDebounced(e.detail.value || '')}
            type="search"
            data-testid="dashboard-templates-search"
            className={styles.searchInput}
          />
        </Flex>
        <div className={styles.cardContainer}>
          {isTemplatesLoading || isRefetching ? (
            <div className={styles.cardsGrid} data-testid="templates-loading">
              {Array.from({ length: 6 }).map((_, index) => (
                <WppSkeleton key={index} variant="rectangle" height="165px" />
              ))}
            </div>
          ) : !templates.length ? (
            <NoTemplates text={message} image={image} />
          ) : (
            <>
              <div className={styles.cardsGrid}>
                {templates.map(template => (
                  <TemplateCard
                    key={template.id}
                    template={template}
                    withContextMenu
                    handleOpenTemplate={() => setParams({ view: 'template', id: template.id })}
                    handleCreateFromTemplate={() =>
                      showCreateProjectModal({ templatePresetId: template.id, showProcessType: false })
                    }
                    handleRemoveTemplate={() =>
                      showDeleteModal({
                        title: t('templates.confirm_remove.title'),
                        subTitle: t('templates.confirm_remove.message'),
                        onDelete: () => handleDelete(template.id),
                      })
                    }
                  />
                ))}
                {isFetchingNextPage && (
                  <>
                    {Array.from({ length: itemsRemaining }).map((_, index) => (
                      <WppSkeleton key={index} variant="rectangle" height="165px" />
                    ))}
                  </>
                )}
              </div>
              <Flex justify="center" ref={setRef} className={styles.spinner}>
                {isTemplatesLoading && <WppSpinner size="l" />}
              </Flex>
            </>
          )}
        </div>
      </Flex>
    </>
  )
}
