import {
  WppTypography,
  WppLabel,
  WppIconFile,
  WppIconVideoClip,
  WppIconPeople,
  WppIconCalendar3Days,
  WppCard,
} from '@platform-ui-kit/components-library-react'
import { HierarchyContainerNodeId } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormRadioGroup } from 'components/form/formRadioGroup/FormRadioGroup'
import { FormSelect, FormSelectOption } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import styles from 'pages/components/projectModal/components/projectForm/ProjectForm.module.scss'
import { SelectProcessType } from 'pages/project/components/canvas/components/selectProcessType/SelectProcessType'
import { ProjectType } from 'types/projects/projects'

const projectTypePreset = [
  {
    value: ProjectType.BLANK,
    icon: <WppIconFile color="var(--wpp-dataviz-color-cat-dark-1)" />,
    title: 'Blank',
  },
  {
    value: ProjectType.PITCH,
    icon: <WppIconVideoClip color="var(--wpp-dataviz-color-cat-dark-3)" />,
    title: 'Pitch',
  },
  {
    value: ProjectType.WORKSHOP,
    icon: <WppIconPeople color="var(--wpp-dataviz-color-cat-dark-5)" />,
    title: 'Workshop',
  },
  {
    value: ProjectType.CAMPAIGN,
    icon: <WppIconCalendar3Days color="var(--wpp-dataviz-color-cat-dark-7)" />,
    title: 'Campaign',
  },
]

const projectTypeOptions = projectTypePreset.map(({ value, icon, title }) => (
  <WppCard key={value} value={value}>
    <Flex direction="column" align="center">
      <Flex justify="center" align="center" gap={10} className={clsx(styles.cardIcon, styles[value])}>
        {icon}
      </Flex>
      <WppTypography type="s-strong">{title}</WppTypography>
    </Flex>
  </WppCard>
))

export const ProjectForm = ({
  showProcessType = true,
  templateId,
}: {
  showProcessType?: boolean
  templateId?: string
}) => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext()
  const {
    osContext: { navigationTree, tenant },
  } = useOs()

  const { mapping } = navigationTree

  const mapNavigationToOption = useCallback(
    (children: string[]) => {
      const options = (children || [])
        .map(id => {
          const { name, type } = mapping[id] || {}

          const handleThisLvl = tenant.hierarchyLevels.some(level => level.type === type)

          if (!handleThisLvl) return null
          return { label: name, value: id }
        })
        .filter(option => option?.label) as FormSelectOption[]

      options.sort((a, b) => a.label?.localeCompare(b.label))

      return options
    },
    [mapping, tenant.hierarchyLevels],
  )

  const [clientWatch, marketWatch] = watch(['client', 'market', 'brand'])

  return (
    <Flex direction="column" gap={69}>
      <Flex direction="column" gap={24}>
        <FormInput
          name="name"
          data-testid="modal-project-name"
          labelConfig={{ text: t('modals.create_project.field_name_label') }}
          placeholder={t('modals.create_project.field_name_placeholder')!}
          required
        />
        <Flex direction="column" gap={8}>
          <WppLabel
            data-testid="project-type-label"
            config={{ text: t('modals.create_project.field_type_label') }}
            typography="s-strong"
          />
          <FormRadioGroup
            data-testid="project-type"
            className={styles.radioGroup}
            withRadioOrCheckbox={false}
            name="type"
          >
            {projectTypeOptions}
          </FormRadioGroup>
        </Flex>

        {showProcessType && (
          <Flex direction="column" gap={8}>
            <WppLabel
              data-testid="project-process-type-label"
              config={{ text: t('modals.create_project.field_process_type_label') }}
              typography="s-strong"
            />
            <SelectProcessType
              name="processType"
              templateId={templateId}
              resetTemplateId={() => setValue('templateId', '')}
            />
          </Flex>
        )}

        <Flex direction="column" gap={24}>
          <div className={styles.grid}>
            <FormSelect
              name="client"
              withSearch
              className={
                // @TODO: CL css var '--input-select-dropdown-max-height' doesn’t  work, remove when they will fix it
                clsx({
                  [styles.selectMaxHeight]:
                    mapNavigationToOption(mapping[HierarchyContainerNodeId]?.children).length > 5,
                })
              }
              data-testid="client-select"
              options={mapNavigationToOption(mapping[HierarchyContainerNodeId]?.children)}
              labelConfig={{ text: t('modals.create_project.field_client_label') }}
              placeholder={t('modals.create_project.field_client_placeholder')!}
              onWppChange={() => {
                setValue('market', '')
                setValue('brand', '')
              }}
              required
              toggleSelection
            />
            <FormSelect
              name="market"
              withSearch
              className={
                // @TODO: CL css var '--input-select-dropdown-max-height' doesn’t work, remove when they will fix it
                clsx({ [styles.selectMaxHeight]: mapNavigationToOption(mapping[clientWatch]?.children).length > 5 })
              }
              data-testid="market-select"
              options={mapNavigationToOption(mapping[clientWatch]?.children)}
              labelConfig={{ text: t('modals.create_project.field_market_label') }}
              placeholder={t('modals.create_project.field_market_placeholder')!}
              required
              toggleSelection
              disabled={!clientWatch || !mapNavigationToOption(mapping[clientWatch]?.children).length}
              onWppChange={() => {
                setValue('brand', '')
              }}
            />
            <FormSelect
              name="brand"
              withSearch
              data-testid="brand-select"
              options={mapNavigationToOption(mapping[marketWatch]?.children)}
              className={
                // @TODO: CL css var '--input-select-dropdown-max-height' doesn’t work, remove when they will fix it
                clsx({ [styles.selectMaxHeight]: mapNavigationToOption(mapping[marketWatch]?.children).length > 5 })
              }
              labelConfig={{ text: t('modals.create_project.field_brand_label') }}
              placeholder={t('modals.create_project.field_brand_placeholder')!}
              required
              toggleSelection
              disabled={!marketWatch || !mapNavigationToOption(mapping[marketWatch]?.children).length}
            />
            <div />
            <FormDatepicker
              className={styles.datePicker}
              name="dueDate"
              placeholder={t('modals.create_project.field_due_date_placeholder')}
              labelConfig={{ text: t('modals.create_project.field_due_date_label') }}
              range
            />
          </div>
        </Flex>
        <FormTextareaInput
          name="description"
          data-testid="project-description"
          labelConfig={{ text: t('modals.create_project.field_description_label') }}
          placeholder={t('modals.create_project.field_description_placeholder')!}
          warningThreshold={580}
          charactersLimit={600}
        />
      </Flex>
    </Flex>
  )
}
