import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { LeanAppDTO, LeanAppType } from 'types/products/leanApp'

interface Props {
  search: string
  types?: LeanAppType[]
}

export const fetchLeanAppsApi = ({ search, types, page = 1, itemsPerPage = 30 }: PaginationParams<Props>) =>
  projectApi.get<PaginatedResponse<LeanAppDTO>>('/lean_apps', {
    params: {
      ...(search ? { 'filter[search]': search } : {}),
      ...(types?.length ? { 'filter[type]': types } : {}),
      page,
      itemsPerPage,
    },
    paramsSerializer: { indexes: null },
  })
