import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/common/emptyState/EmptyState.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { SvgEmptySearch } from 'components/svg/SvgEmptySearch'

type Props = {
  message?: {
    title?: string
    subtitle?: string
  }
  showImage?: boolean
  margin?: string
} & JSX.IntrinsicElements['div']

export const EmptyState = ({ message, showImage, margin, ...rest }: Props) => {
  return (
    <Flex direction="column" align="center" justify="center" gap={8} {...rest}>
      {showImage && <SvgEmptySearch className={styles.image} />}
      {message?.title && <WppTypography type="m-strong">{message.title}</WppTypography>}
      {message?.subtitle && <WppTypography type="s-body">{message.subtitle}</WppTypography>}
    </Flex>
  )
}
