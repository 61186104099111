import { AutocompleteOption } from '@platform-ui-kit/components-library'
import {
  WppTypography,
  WppButton,
  WppActionButton,
  WppIconReset,
  WppCheckbox,
} from '@platform-ui-kit/components-library-react'
import { useMemo, useRef } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import { Flex } from 'components/common/flex/Flex'
import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { t } from 'i18next'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import { useSearchUsers } from 'pages/project/components/canvas/components/selectPerson/utils'
import { initialFilesFilters } from 'pages/project/components/files/Files'
import styles from 'pages/project/components/files/Files.module.scss'
import { getAllFileFormatKeys } from 'pages/project/components/files/utils'
import { FilesFilter } from 'types/projects/Files'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  filters?: FilesFilter
  onFiltersSave: (filters: FilesFilter) => void
}

export const FilesFiltersModal = ({ isOpen, onClose, onCloseComplete, filters, onFiltersSave }: Props) => {
  const form = useForm({ defaultValues: filters })
  const { usersOptions, isUsersLoading, setUsersSearch } = useSearchUsers()

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    getValues,
  } = form

  const membersRef = useRef<HTMLWppAutocompleteElement>(null)

  const onSubmit = handleSubmit(value => {
    onFiltersSave(value)
    onClose()
  })

  const getAllFileExtensionsOptions = useMemo(() => {
    return getAllFileFormatKeys
      ? getAllFileFormatKeys().map(key => ({
          value: key,
          label: key.charAt(0).toUpperCase() + key.slice(1),
        }))
      : []
  }, [])

  const onReset = () => {
    reset(initialFilesFilters)
  }

  const watchAllFields = watch(['types', 'uploadedBy', 'pinned'])

  const syncExternalMembers = (options: AutocompleteOption[]) => {
    setValue(
      'uploadedBy',
      getValues('uploadedBy').filter(member => options.find(option => option.id === member.id)),
    )
  }

  return (
    <FormProvider {...form}>
      <SideModal
        size="m"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid="files-filter-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('project.files.filters.title')}
        </WppTypography>
        <div slot="body">
          <Flex direction="column" className={styles.filtersWrapper} gap={16}>
            <Flex direction="column" className={styles.filtersWrapper} gap={24}>
              <FormAutocomplete
                ref={membersRef}
                style={{ flexGrow: 1 }}
                required
                name="uploadedBy"
                multiple
                placeholder={t('project.files.filters.field_uploaded_by_placeholder')!}
                labelConfig={{ text: t('project.files.filters.field_uploaded_by_label') }}
                loading={isUsersLoading}
                onWppChange={({ detail }) => {
                  if (detail.reason === 'removeOption') {
                    syncExternalMembers(detail.value as AutocompleteOption[])
                  }
                }}
                onWppSearchValueChange={event => setUsersSearch(event.detail)}
                options={usersOptions}
                data-testid="members-autocomplete"
                renderPillContent={option => <>{option.label || option.email}</>}
              />

              <FormSelect
                name="types"
                type="multiple"
                data-testid="type-select"
                options={getAllFileExtensionsOptions}
                labelConfig={{ text: t('project.files.filters.field_type_label') }}
                placeholder={t('project.files.filters.field_type_placeholder')!}
                withFolder
                required
                withSearch
              />

              <WppCheckbox
                name="pinned"
                required
                checked={getValues('pinned')}
                labelConfig={{ text: t('project.files.filters.field_pinned_label') }}
                onWppChange={({ detail: { checked } }) => setValue('pinned', checked)}
              />
            </Flex>
          </Flex>
        </div>
        <Flex justify="between" slot="actions">
          <Flex>
            {getAppliedFilters(watchAllFields) > 0 && (
              <WppActionButton variant="primary" onClick={onReset}>
                <WppIconReset className={styles.resetIcon} />
                {t('common.btn_reset')}
              </WppActionButton>
            )}
          </Flex>
          <Flex gap={12}>
            <WppActionButton variant="secondary" onClick={onClose}>
              {t('common.btn_cancel')}
            </WppActionButton>
            <WppButton size="s" type="submit" variant="primary" loading={isSubmitting}>
              {t('common.btn_apply')}
            </WppButton>
          </Flex>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showFilesFiltersModal } = createNiceModal<Props>(FilesFiltersModal, 'files-filters-modal')
