// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SJzMJ{display:flex;flex-direction:column;height:100%;margin:24px 32px}.OZwUV{margin-top:32px;padding:24px 32px;background-color:var(--wpp-grey-color-000)}.st6OZ{max-height:45vh;object-fit:contain}.ktXWj{--wpp-typography-color: var(--wpp-grey-color-800);max-width:60%}.FamA6{flex-grow:1}.U7N32{color:var(--wpp-primary-color-500);text-decoration:underline;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/projectDemo/components/appPresentation/AppPresentation.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,gBAAA,CAGF,OACE,eAAA,CACA,iBAAA,CACA,0CAAA,CAGF,OACE,eAAA,CACA,kBAAA,CAGF,OACE,iDAAA,CAEA,aAAA,CAGF,OACE,WAAA,CAGF,OACE,kCAAA,CACA,yBAAA,CACA,cAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  margin: 24px 32px;\n}\n\n.content {\n  margin-top: 32px;\n  padding: 24px 32px;\n  background-color: var(--wpp-grey-color-000);\n}\n\n.image {\n  max-height: 45vh;\n  object-fit: contain;\n}\n\n.textWrapper {\n  --wpp-typography-color: var(--wpp-grey-color-800);\n\n  max-width: 60%;\n}\n\n.noContent {\n  flex-grow: 1;\n}\n\n.link {\n  color: var(--wpp-primary-color-500);\n  text-decoration: underline;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SJzMJ`,
	"content": `OZwUV`,
	"image": `st6OZ`,
	"textWrapper": `ktXWj`,
	"noContent": `FamA6`,
	"link": `U7N32`
};
export default ___CSS_LOADER_EXPORT___;
