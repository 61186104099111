// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aPGQg{display:flex;flex-direction:column;gap:8px;width:100%;overflow:auto}.nSJAs{width:24px;height:24px}.nNUgk{color:var(--wpp-grey-color-800)}.o1gh4::part(button){width:100%;min-width:100px}.vZEjk{margin:40px 0}.Wz7MB{color:var(--wpp-grey-color-800)}.Yk4mK{padding:6px}.FDbZp{color:var(--wpp-grey-color-1000)}.iR1vf{grid-column:1/-1;padding-bottom:40px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/attachFilesModal/AttachFilesModal.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,UAAA,CACA,aAAA,CAGF,OACE,UAAA,CACA,WAAA,CAGF,OACE,+BAAA,CAIA,qBACE,UAAA,CACA,eAAA,CAIJ,OACE,aAAA,CAGF,OACE,+BAAA,CAGF,OACE,WAAA,CAGF,OACE,gCAAA,CAGF,OACE,gBAAA,CACA,mBAAA","sourcesContent":[".radioGroup {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n  overflow: auto;\n}\n\n.iconSize {\n  width: 24px;\n  height: 24px;\n}\n\n.fileExt {\n  color: var(--wpp-grey-color-800);\n}\n\n.addFilesBtn {\n  &::part(button) {\n    width: 100%;\n    min-width: 100px;\n  }\n}\n\n.noResult {\n  margin: 40px 0;\n}\n\n.greyColor800 {\n  color: var(--wpp-grey-color-800);\n}\n\n.iconWrapper {\n  padding: 6px;\n}\n\n.label {\n  color: var(--wpp-grey-color-1000);\n}\n\n.nextPageSpinner {\n  grid-column: 1/-1;\n  padding-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroup": `aPGQg`,
	"iconSize": `nSJAs`,
	"fileExt": `nNUgk`,
	"addFilesBtn": `o1gh4`,
	"noResult": `vZEjk`,
	"greyColor800": `Wz7MB`,
	"iconWrapper": `Yk4mK`,
	"label": `FDbZp`,
	"nextPageSpinner": `iR1vf`
};
export default ___CSS_LOADER_EXPORT___;
