import { projectApi } from 'api'
import { CanvasApplicationType } from 'types/projects/workflow'

export interface ItemReferenceDataCreate {
  id: string
  type: CanvasApplicationType
  name: string
}

export interface ApplicationPostItemRequest {
  projectId: string
  phaseId?: string
  activityId?: string
  applications: {
    coordinateX?: number
    coordinateY?: number
    referenceData: ItemReferenceDataCreate
    name?: string
    startDate?: string
    endDate?: string
    assignUser?: string
  }[]
}

export const createApplication = (body: ApplicationPostItemRequest) => projectApi.post('/applications', body)
