export enum LeanAppType {
  LINK_EMBEDDED = 'link_embedded',
  LINK_DETACHED = 'link_detached',
  CUSTOM_PAGE = 'custom_page',
  MIRO_BOARD = 'miro_board',
  CAAS = 'caas',
}

export interface LeanAppConfig {
  url: string
}

export interface LeanAppLogo {
  key: string
  url: string
}

export interface LeanAppDTO {
  id: string
  name: string
  type: LeanAppType
  config: LeanAppConfig
  description?: string
  logo?: LeanAppLogo
}
