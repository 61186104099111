import {
  WppAvatarGroup,
  WppCard,
  WppIconEdit,
  WppListItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { NavigationTreeMapping, HierarchyLevelType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { RowClickedEvent } from 'ag-grid-community'
import { FC, useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar, getAvatarColor } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, TableInfinite } from 'components/common/table'
import { NoRecordsSvg } from 'components/svg/NoRecordsSvg'
import { SvgEmptySearch } from 'components/svg/SvgEmptySearch'
import { TableKey } from 'constants/table'
import { useStableCallback } from 'hooks/useStableCallback'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { ProjectManageMenu } from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu'
import { ProjectStatusTag } from 'pages/dashboard/components/projectsCardView/components/projectStatus/ProjectStatusTag'
import styles from 'pages/dashboard/components/projectsTableView/ProjectsTableVire.module.scss'
import { useProjectListLoader } from 'pages/dashboard/components/projectsTableView/useProjectListLoader'
import { ProjectTypeTag } from 'pages/dashboard/components/projectTypeTag/ProjectTypeTag'
import { Project, ProjectFilter, ProjectStatus } from 'types/projects/projects'
import { fullName } from 'utils/common'
import { formatDate } from 'utils/dateFormat'
import { hasClosestInteractiveElement } from 'utils/dom'
import { findNavigationTreeParentNodeAzId } from 'utils/navigationTree'
import { routesManager } from 'utils/routesManager'

const NotDefined = () => (
  <WppTypography type="s-body" className={styles.notDefined}>
    -
  </WppTypography>
)

const workspaceCell = (mapping: NavigationTreeMapping, nodeType: HierarchyLevelType, data?: Project) => {
  const tree = findNavigationTreeParentNodeAzId(data?.contextWorkspace, mapping)
  const element = tree?.find(elem => elem.type === nodeType)

  if (element) return <WppTypography type="s-body">{element?.name}</WppTypography>

  return <NotDefined />
}

interface Props {
  filter: ProjectFilter
}

export const ProjectsTableView: FC<Props> = ({ filter }) => {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { search, ownership, type, workspace } = filter
  const { loader } = useProjectListLoader({ filter })

  const [isEmpty, setIsEmpty] = useState(false)

  const {
    osContext: {
      navigationTree: { mapping },
      userDetails: { dateLocale },
    },
  } = useOs()

  const isFilterActive = [ownership, type.length, Array.isArray(workspace)].some(Boolean)

  const gridOptions = {
    rowStyle: { cursor: 'pointer' },
  }

  const columnDefs = useMemo<ColDef<Project>[]>(() => {
    return [
      {
        colId: 'name',
        flex: 2,
        headerName: t('project.table.project_name')!,
        cellRenderer: ({ data }) => (
          <WppTypography className={styles.overflow} type="s-body" title={data!.name} data-testid="table-project-name">
            {data!.name}
          </WppTypography>
        ),
        tooltipValueGetter: ({ data }) => data?.name,
      },
      {
        colId: 'status',
        flex: 1,
        headerName: t('project.table.project_status')!,
        cellRenderer: ({ data }) => <ProjectStatusTag status={data!.status} className={styles.status} />,
      },
      {
        colId: 'type',
        flex: 1,
        headerName: t('project.table.project_type')!,
        cellRenderer: ({ data }) => <ProjectTypeTag type={data!.type} />,
        cellClassRules: {
          [styles.cell]: () => true,
        },
      },
      {
        colId: 'startDate',
        flex: 1,
        headerName: t('common.start_date')!,
        valueFormatter: ({ data }) => (data?.startDate ? formatDate(data.startDate, dateLocale)! : '-'),
      },
      {
        colId: 'endDate',
        flex: 1,
        headerName: t('common.end_date')!,
        valueFormatter: ({ data }) => (data?.endDate ? formatDate(data.endDate, dateLocale)! : '-'),
      },
      {
        colId: 'client',
        flex: 1,
        headerName: t('project.table.project_client')!,
        cellRenderer: ({ data }) => workspaceCell(mapping, HierarchyLevelType.Client, data),
      },
      {
        colId: 'market',
        flex: 1,
        headerName: t('project.table.project_market')!,
        cellRenderer: ({ data }) => workspaceCell(mapping, HierarchyLevelType.Market, data),
      },
      {
        colId: 'brand',
        flex: 1,
        headerName: t('project.table.project_brand')!,
        cellRenderer: ({ data }) => workspaceCell(mapping, HierarchyLevelType.Brand, data),
      },
      {
        colId: 'owner',
        flex: 1,
        headerName: t('project.table.project_owners')!,
        cellClassRules: {
          [styles.avatarCell]: () => true,
        },
        cellRenderer: ({ data }) => (
          <>
            {data?.owners?.length === 1 ? (
              <Flex align="center" gap={16} className={styles.overflow}>
                <Avatar
                  className={styles.noShrink}
                  size="xs"
                  name={fullName(data?.owner?.firstname, data?.owner?.lastname)}
                  src={data?.owner?.avatarUrl ?? ''}
                  withTooltip
                  tooltipConfig={{ placement: 'top' }}
                />
              </Flex>
            ) : (
              <WppAvatarGroup
                className={styles.otherMembers}
                onClick={e => {
                  e.stopPropagation()
                }}
                maxAvatarsToDisplay={4}
                size="xs"
                withTooltip
                users={data?.owners?.map(owner => ({
                  name: fullName(owner.firstname, owner.lastname),
                  src: owner.avatarUrl ?? '',
                  color: getAvatarColor({ name: owner.firstname }),
                }))}
              />
            )}
          </>
        ),
      },
      {
        cellRenderer: ({ data }) => (
          <>
            {data && (
              <ProjectManageMenu
                project={data}
                securedChildren={
                  data.status === ProjectStatus.ACTIVE && (
                    <WppListItem onWppChangeListItem={() => showProjectEditModal({ project: data })}>
                      <WppIconEdit slot="left" />
                      <WppTypography slot="label" type="s-body" data-testid="edit-action">
                        {t('project.list.btn_edit_project')}
                      </WppTypography>
                    </WppListItem>
                  )
                }
              />
            )}
          </>
        ),
        width: 60,
      },
    ]
  }, [mapping, t, dateLocale])

  const handleOnRowClicked = useStableCallback(({ event, data }: RowClickedEvent<Project>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target, ['.wpp-avatar-group'])) {
      navigate(routesManager.project.workflow.getURL({ id: data!.id }))
    }
  })

  const noRowsOverlayComponent = useCallback(() => {
    const message = !isEmpty
      ? t('project.list.empty_state_no_search_results', { query: search })
      : t('project.list.empty_state_no_projects')

    const image = !isEmpty ? <SvgEmptySearch className={styles.image} /> : <NoRecordsSvg className={styles.image} />
    return (
      <Flex className={styles.notFound} direction="column" align="center" gap={12} data-testid="not-selected-view">
        {image}
        <WppTypography type="m-strong">{message}</WppTypography>
        {isFilterActive && (
          <WppTypography type="xs-body" className={styles.errorTextWithFilters}>
            Try adjusting filters to find what you're looking for.
          </WppTypography>
        )}
      </Flex>
    )
  }, [isEmpty, isFilterActive, search, t])

  return (
    <WppCard className={styles.card}>
      <TableInfinite
        gridOptions={gridOptions}
        tableKey={TableKey.PROJECT_LIST}
        loader={loader}
        cacheBlockSize={50}
        rowHeight={48}
        columnDefs={columnDefs}
        onRowClicked={handleOnRowClicked}
        onLoadSuccess={({ isEmptySource }) => setIsEmpty(isEmptySource && !search)}
        noRowsOverlayComponent={noRowsOverlayComponent}
      />
    </WppCard>
  )
}
