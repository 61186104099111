import NiceModal, { NiceModalHandler, useModal as useNiceModal } from '@ebay/nice-modal-react'
import { FC, useCallback, useEffect } from 'react'

export interface NiceModalWrappedProps {
  id: string
  isOpen: boolean
  onClose: () => void
  onOpen?: () => void
  onCloseComplete: () => void
}

type OmittedProps<T> = Omit<T, 'isOpen' | 'onClose' | 'onCloseComplete' | 'id'>

type ShowModalProps<T> = OmittedProps<T> & { onClose?: () => void }

export function createNiceModal<T extends NiceModalWrappedProps>(
  Component: FC<T>,
  id: string,
  dependencies: (() => NiceModalHandler)[] = [],
) {
  const WrappedComponent = NiceModal.create<T>((props: T) => {
    const { onOpen } = props
    const { visible, hide, remove } = useNiceModal()

    const keepMounting = dependencies.filter(hook => hook().visible).length > 0

    const onClose = useCallback(() => {
      hide()
      props.onClose?.()
    }, [hide, props])

    useEffect(() => {
      if (visible) {
        onOpen?.()
      }
    }, [onOpen, visible])

    const onCloseComplete = useCallback(() => !keepMounting && remove(), [remove, keepMounting])

    return (
      <Component
        {...{
          ...props,
          isOpen: visible,
          onClose,
          onCloseComplete,
          id,
        }}
      />
    )
  })

  NiceModal.register(id, WrappedComponent)

  const showModal = (props?: ShowModalProps<T>) => NiceModal.show(id, props)

  const hideModal = () => NiceModal.hide(id)

  const useModal = () => useNiceModal(id)

  return {
    showModal,
    hideModal,
    useModal,
  }
}
