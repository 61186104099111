import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { Outlet } from 'react-router-dom'

import { ProjectProvider } from 'providers/project/ProjectProvider'

export const ProjectPage = () => {
  return (
    <ProjectProvider>
      <NiceModalProvider>
        <Outlet />
      </NiceModalProvider>
    </ProjectProvider>
  )
}
